import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Metric from './components/Metric';

import Navigation from './components/Navigation';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { NavigationProvider } from './utilities/NavigationContext';

class App extends React.Component {

  render() {

    return (
      <Router>

          <NavigationProvider>
          <ToastContainer />
            <Navigation />
            <Routes>

              <Route path="/" element={<Metric />} />

           

            </Routes>
          </NavigationProvider>
 
      </Router>
    );
  }
}

export default App;
