import React from 'react';
import Moment from 'react-moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const header = "In the past week :";
const questions = [
    {type: "S", question: "I found it hard to wind down " , value: [0,1,2,3] },
    {type: "A", question: "I was aware of dryness of my mouth" , value: [0,1,2,3] },
    {type: "D", question: "I couldn’t seem to experience any positive feeling at all " , value: [0,1,2,3] },
    {type: "A", question: "I experienced breathing difficulty (eg, excessively rapid breathing, breathlessness in the absence of physical exertion)" , value: [0,1,2,3] },
    {type: "D", question: "I found it difficult to work up the initiative to do things" , value: [0,1,2,3] },
    {type: "S", question: "I tended to over-react to situations " , value: [0,1,2,3] },
    {type: "A", question: "I experienced trembling (eg, in the hands) " , value: [0,1,2,3] },
    {type: "S", question: "I felt that I was using a lot of nervous energy" , value: [0,1,2,3] },
    {type: "A", question: "I was worried about situationsin which I might panic and make a fool of self" , value: [0,1,2,3] },
    {type: "D", question: "I felt that I had nothing to look forward to" , value: [0,1,2,3] },
    {type: "S", question: "I found myself getting agitated" , value: [0,1,2,3] },
    {type: "S", question: "I found it difficult to relax " , value: [0,1,2,3] },
    {type: "D", question: "I felt down-hearted and blue " , value: [0,1,2,3] },
    {type: "S", question: "I was intolerant of anything that kept me from getting on with what I was doing" , value: [0,1,2,3] },
    {type: "A", question: "I felt I was close to panic " , value: [0,1,2,3] },
    {type: "D", question: "I was unable to become enthusiastic about anything " , value: [0,1,2,3] },
    {type: "D", question: "I felt I wasn’t worth much as a person " , value: [0,1,2,3] },
    {type: "S", question: "I felt that I was rather touchy" , value: [0,1,2,3] },
    {type: "A", question: "I was aware of the action of my heart in the absence of physicalexertion (eg, sense of heart rate increase, heart missing a beat)" , value: [0,1,2,3] },
    {type: "A", question: "I felt scared without any good reason" , value: [0,1,2,3] },
    {type: "D", question: "I felt that life was meaningless " , value: [0,1,2,3] },

]

const explanation = `DASS-21 stands for Depression Anxiety Stress Scales-21, which is a commonly used self-report measure of emotional distress. It consists of 21 items that assess three dimensions of emotional states, including depression, anxiety, and stress. Each of the three subscales has 7 items that are rated on a four-point Likert scale, ranging from 0 (Did not apply to me at all) to 3 (Applied to me very much or most of the time).
The scores for each subscale range from 0 to 21, with higher scores indicating greater severity of the symptoms. The total score can range from 0 to 63, which is obtained by summing the scores for all three subscales. The DASS-21 is widely used in clinical and research settings to assess the severity of emotional distress and to monitor changes in symptoms over time.`
const citation = `Lovibond, S.H. & Lovibond, P.F. (1995).  Manual for the Depression Anxiety Stress Scales. (2nd. Ed.)  Sydney: Psychology Foundation.`
const title = "DASS21";
const instrument = "DASS21";

class DASS21 extends React.Component {

    constructor(props) {
            super(props);
            this.state = {
                identity: localStorage.getItem("auth"),
                score: "n/a",
                latestDate:"",
                D : 0,
                A : 0,
                S: 0,
                tool: props.tool
            };

            this.calculate = this.calculate.bind(this);
            this.save = this.save.bind(this);
            this.cancel = this.cancel.bind(this);

    };

    printing = () => {
        var htmlcode = document.getElementById('tool').innerHTML;
        var iframe = document.getElementById("CaredevoPrint");
        var printDocument = iframe.contentDocument;
        var printPreview = iframe.contentWindow;
        printPreview.focus();
     
        printDocument.open();
        printDocument.write(`<!DOCTYPE html>
        <html>
        <head>
        <link rel="stylesheet" type="text/css" media='print' href="/dist/print.css">
        </head>
        <style>

        body {
            line-height : 1.6;
            font-size : 12pt;
            padding-bottom  :50pt;
        }
        iframe {
            display : none;
        }
        @media print {        
            @page {
                size: A4;
                margin-top : 0.80cm;
                margin-bottom : 0.80cm;
            }

        * {
            -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
            color-adjust: exact !important;  /*Firefox*/
        }
        .section {
            page-break-inside: avoid;
            
        }

        .options {
            display : flex;
            flex-direction: row;
            margin-left : 20pt;
            margin-bottom : 10pt;
            border-bottom-style : solid;
            border-width: 0.5px;
            width: 490pt;
        }

        .optionblock {
            display : flex;
            flex-direction : row;
        }

        .qline {
            display : grid;
            grid-template-columns : 20pt 490pt;
           
        }

        .title {
            padding : 30pt
            width : 100%;
            height : 20pt;
            background-color: #42b3f5;
            background-image: linear-gradient(to right, #42b3f5 , white);
            border-radius: 5pt;

        }

        .title, .score {
            font-size:large;
            font-family: Arial, Helvetica, sans-serif;
            font-weight:600;
            color: black;
            padding: 10pt;
            text-align:center
        }
        .header {
            font-size : 13pt;
            color : black;
            font-weight : 600;
            margin-left : 10pt;
            margin-bottom : 20pt;
            margin-top : 20pt;
        }
        .explanation {
            margin-top : 10pt;
            margin-left : 20pt;
            white-space : pre-line;
            width: 450pt;
            text-align : justify;
            font-size : 10pt;
          
        }
        .button {
            display: none;
        }
        
        .decoration {
            position: fixed;
            font-family: Arial, Helvetica, sans-serif;
            font-weight:600;
            top:  300px;
            left : 10px;
            width : 300px;
            color : #42b3f5;
            opacity : 0.1;
            font-size : 140pt;
            letter-spacing : 2pt;
            z-index : -1;
        }
        </style>
        <body>`)
        printDocument.write(htmlcode);
        printDocument.write("<div class='decoration'>DASS21</div>");
        printDocument.write("</body></html>");
        printPreview.print();
        printPreview.close();
  
    }

    renderContent() {
        return (
            <div>
                <div className='header p-4 bg-gray-200 sticky top-0'>{header}</div>

                {questions.map((item, index) => (
                    <div className='section flex flex-col border-b-2' key={index}>
                    <div className='qline p-2 w-10/12 xs:w-auto flex flex-row gap-2'><div>{questions.indexOf(item)+1}.</div><div>{item.question}</div></div>
                    <div className='options flex flex-row xs:flex-col w-11/12 xs:w-auto ml-6'>
                        <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",0]" } value={item.value[0]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> Never</div>
                        <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",1]" } value={item.value[1]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> Sometimes</div>
                        <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",2]" } value={item.value[2]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> Often</div>
                        <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",3]" } value={item.value[3]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> Almost always</div>
                    </div>
                    </div>
                ))}
            </div>
     
        )

    }

    singleOption(e) {
        var current = JSON.parse(e.target.id);
        var qNumber = JSON.stringify(current[0]);
        var optionChecked = JSON.stringify(current[1]); 
        
        var optionNumber = questions[0].value.length;
        for (let i=0; i < optionNumber; i++) {
            var option = "[" + qNumber + "," + i + "]";
            document.getElementById(option).checked = false;
            document.getElementById(option).removeAttribute("checked");
        }

        var checkingOption = "[" + qNumber + "," + optionChecked + "]"
        document.getElementById(checkingOption).checked = true;
        document.getElementById(checkingOption).setAttribute("checked", "true");


    }

    calculate() {
      
        var finalscore = 0;
        var D = 0;
        var A = 0;
        var S = 0;

        var optionNumber = questions[0].value.length;
        var element, checking;
        var noBlank = 0;
        questions.map(item => {
            var questionNumber = questions.indexOf(item);
           
            for (let i=0; i < optionNumber; i++) {
                element = "[" + questionNumber + "," + i +"]";
                
                checking = document.getElementById(element).checked;

                if (checking === true) {
                    var value = parseInt(document.getElementById(element).value)
                    finalscore = finalscore + value;
               
                    if (item.type === 'D') {
                        D = D + value;
                    } else if (item.type === 'A') {
                        A = A + value;
                    } else if (item.type === 'S') {
                        S = S + value;
                    } 
                    noBlank = noBlank + 1;
                }
            }
            
        })

        if (noBlank !== questions.length) {
            toast.error(`You must answer all questions !`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar : false
            });
        }
        else if (noBlank === questions.length) {
            toast.success(`Total score successfully calculated`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar : false
            });
            this.setState({score: finalscore, D: D, A: A, S: S})
            this.props.addData({ tool: 'DASS21', result:  finalscore, D: D, A: A, S: S, interpretation: 'Higher scores indicating greater severity of the symptoms' });
        }
      
    }

    cancel() {
        this.props.setState({tool: '', score: 'n/a'})
    }

    async save(e) {

        //save tool to mh_tool
        e.preventDefault();
        var newData = {};

   
        // axios post request
        newData['patientId'] = this.state.patientId;
        newData['userId'] = this.state.userId;
        newData['instrument'] = instrument;
        newData['score'] = `Depression: ${this.state.D}/ Anxiety: ${this.state.A}/ Stress: ${this.state.S}`;
        

        const interpretation = (state, subset) => {
            var result;
            if (state < 10) {
                result = `normal ${subset}`;
            } else if (state > 9 && state < 14) {
                result = `mild ${subset}`;
            } else if (state > 13 && state < 21) {
                result = `moderate ${subset}`;
            } else if (state > 20 && state < 28) {
                result = `severe ${subset}`;
            } else if (state > 28) {
                result = `extremely severe ${subset}`;
            }

            return result;
        }

        newData['notes'] = `Result indicates ${interpretation(this.state.D, "depression")}, ${interpretation(this.state.A, "anxiety")}, and ${interpretation(this.state.S, "stress")}`

        //check if it's been calculated first
        if (newData.score === 'n/a') {
            toast.error("Need to calculate the score before saving")
        } else {

            //close this panel by updating tool state in parent component
            this.props.setState({tool: '', DASS21: [newData.score, newData.notes], score: 'n/a'})
        }

    }

    render() {
        var latestDate = this.state.latestDate;
     
        return (
            <div className="fixed top-0 left-0 h-full w-full z-10 bg-gray-900/80">
                <div id='tool' className="bg-white rounded p-6 xs:p-4 mx-auto  mt-20 xs:mt-0 h-4/5 w-8/12 xs:h-full xs:w-full">
                    <div className='title bg-sky-800 text-white text-md -mt-6 w-2/5 xs:w-4/5 -ml-6 px-3  py-2' >{title}</div>
                    <div className='mt-4 h-3/4 xs:h-3/4 overflow-y-auto'>
                
                        {this.renderContent()}
                    </div>
                    <div className='mt-4 text-center'>
                    <div>
                        <button onClick={this.calculate} className='bg-yellow-600 focus:bg-yellow-700 font-semibold text-white p-2 w-32 xs:w-20 shadow-lg mx-2 rounded-lg'>Calculate</button>
                        { this.state.score === 'n/a' || this.state.tool === true ? (
                            <></>
                        ): (
                            <>
                            <button onClick={this.save} className='bg-sky-700 focus:bg-sky-800 font-semibold text-white p-2 w-32 xs:w-20 shadow-lg mx-2 rounded-lg'>Save</button>
                            </>
                        )}
                        { this.state.tool === true ? (
                            <>
                            <button onClick={this.printing} className=' bg-sky-700 focus:bg-sky-800 font-semibold text-white p-2 w-32 xs:w-20 shadow-lg mx-2 rounded-lg'>Print</button>
                            </>
                        ): (<></>)}
                        <button onClick={this.cancel} className='bg-red-700 focus:bg-red-800 font-semibold text-white p-2 w-32 xs:w-20 shadow-lg mx-2 rounded-lg'>Close</button>
                    </div>
                    <div className='score mt-2 xs:mt-1 font-semibold text-2xl xs:text-sm'>
                        Score : {this.state.score}
                        <p className='text-xs'>Depression: {this.state.D} &nbsp; Anxiety: {this.state.A} &nbsp; Stress : {this.state.S}</p>
                        { latestDate ? (
                            <div className='text-sm'>last assessment saved on  <Moment format='DD MMMM YYYY'>{latestDate}</Moment></div>
                        ): (
                            <></>
                        )}
                    </div>
                    <div className='explanation invisible'><b>Explanation</b> <p>{explanation}</p></div>
                    <div className='explanation invisible'><b>Developer</b> <p>{citation}</p></div>
                    <iframe id="CaredevoPrint" className='hidden'></iframe>
                    </div>

                </div>  
                <ToastContainer />
            </div>
        )

    }

};

export default DASS21;

