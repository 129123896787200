import React from 'react';
import Moment from 'react-moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const header = "In the past 4 weeks";
const questions = [
    {question: "About how often did you feel tired out for no good reason ?" , value: [1,2,3,4,5] },
    {question: "About how often did you feel nervous ?" , value: [1,2,3,4,5] },
    {question: "About how often did you feel so nervous that nothing could calm you down ?" , value: [1,2,3,4,5] },
    {question: "About how often did you feel hopeless ?" , value: [1,2,3,4,5] },
    {question: "About how often did you feel restless or fidgety ?" , value: [1,2,3,4,5] },
    {question: "About how often did you feel so restless you could not sit still ?", value: [1,2,3,4,5] },
    {question: "About how often did you feel depressed ?" , value: [1,2,3,4,5] },
    {question: "About how often did you feel that everything was an effort ?", value: [1,2,3,4,5] },
    {question: "About how often did you feel so sad that nothing could cheer you up ?"  , value: [1,2,3,4,5] },
    {question: "About how often did you feel worthless ", value: [1,2,3,4,5] },
]

const title = "K10 - Distress Scale";
const instrument = "K10";
const explanation = `K-10 stands for "Kessler", which refers to the name of the lead author of the study that developed the questionnaire, Dr. Ronald C. Kessler. The K-10 questionnaire was developed by a team of researchers led by Dr. Kessler at Harvard Medical School and was first published in 2002 as a tool for assessing psychological distress in the general population.
K-10 distress refers to the level of psychological distress or emotional disturbance that an individual experiences, as measured by the K-10 questionnaire. The K-10 is a widely used screening tool for psychological distress, and scores on the questionnaire can be used to identify individuals who may benefit from further assessment or treatment for anxiety and depression.
A K-10 distress score of 10 to 19 indicates low levels of psychological distress, while scores of 20 to 29 suggest moderate levels of distress. Scores of 30 or higher are indicative of high levels of psychological distress and may warrant further evaluation by a mental health professional.`
const citation = `Kessler, R.C., Barker, P.R., Colpe, L.J., Epstein, J.F., Gfroerer, J.C., Hiripi, E., Howes, M.J, Normand, S-L.T., Manderscheid, R.W., Walters, E.E., Zaslavsky, A.M. (2003). Screening for serious mental illness in the general population. Archives of General Psychiatry, 60(2), 184-189.

Copyright © World Health Organization 2003 `

class K10 extends React.Component {

    constructor(props) {
            super(props);
            this.state = {
                identity: localStorage.getItem("auth"),
                score: "n/a",
                latestDate:"",
                tool : props.tool
            };

            this.calculate = this.calculate.bind(this);
            this.save = this.save.bind(this);
            this.cancel = this.cancel.bind(this);

    };

    printing = () => {
        var htmlcode = document.getElementById('tool').innerHTML;
        var iframe = document.getElementById("CaredevoPrint");
        var printDocument = iframe.contentDocument;
        var printPreview = iframe.contentWindow;
        printPreview.focus();
     
        printDocument.open();
        printDocument.write(`<!DOCTYPE html>
        <html>
        <head>
        <link rel="stylesheet" type="text/css" media='print' href="/dist/print.css">
        </head>
        <style>

        body {
            line-height : 1.6;
            font-size : 12pt;
            padding-bottom  :50pt;
        }
        iframe {
            display : none;
        }

        input {
            outline : none;
            border : transparent;
        }
        @media print {        
            @page {
                size: A4;
                margin-top : 0.80cm;
                margin-bottom : 0.80cm;
            }

        * {
            -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
            color-adjust: exact !important;  /*Firefox*/
        }
        .section {
            page-break-inside: avoid;
            
        }

        .options {
            display : flex;
            flex-direction: row;
            margin-left : 20pt;
            margin-bottom : 10pt;
            border-bottom-style : solid;
            border-width: 0.5px;
            width: 490pt;
        }

        .optionblock {
            display : flex;
            flex-direction : row;
        }

        .qline {
            display : grid;
            grid-template-columns : 20pt 490pt;
           
        }

        .title {
            padding : 30pt
            width : 100%;
            height : 20pt;
            background-color: #42b3f5;
            background-image: linear-gradient(to right, #42b3f5 , white);
            border-radius: 5pt;
        }

        .title, .score {
            font-size:large;
            font-family: Arial, Helvetica, sans-serif;
            font-weight:600;
            color: black;
            padding: 10pt;
            text-align:center
        }
        .header {
            font-size : 13pt;
            color : black;
            font-weight : 600;
            margin-left : 10pt;
            margin-bottom : 20pt;
            margin-top : 20pt;
        }
        .explanation {
            margin-top : 10pt;
            margin-left : 20pt;
            white-space : pre-line;
            width: 450pt;
            text-align : justify;
            font-size : 10pt;
          
        }
        .button {
            display: none;
        }
        .decoration {
            position: fixed;
            font-family: Arial, Helvetica, sans-serif;
            font-weight:600;
            top:  300px;
            left : 100px;
            width : 300px;
            color : #42b3f5;
            opacity : 0.1;
            font-size : 140pt;
            letter-spacing : 2pt;
            z-index : -1;
        }
        </style>
        <body>`)
        printDocument.write(htmlcode);
        printDocument.write("<div class='decoration'>K10</div>");
        printDocument.write("</body></html>");
        printPreview.print();
        printPreview.close();
  
    }
    
    renderContent() {
        return (
            <div>
                <div className='header p-4 bg-gray-200 sticky top-0'>{header}</div>

                {questions.map((item, index) => (
                        <div className='section flex flex-col border-b-2' key={index}>
                        <div className='qline p-2 w-10/12 xs:w-auto flex flex-row gap-2'><div>{questions.indexOf(item)+1}.</div><div>{item.question}</div></div>
                        <div className='options flex flex-col options w-11/12 text-sm xs:w-auto ml-6'>
                            <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",0]" } value={item.value[0]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> None of the time</div>
                            <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",1]" } value={item.value[1]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> A little of the time</div>
                            <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",2]" } value={item.value[2]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> Some of the time</div>
                            <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",3]" } value={item.value[3]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> Most of the time</div>
                            <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",4]" } value={item.value[4]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> All the time</div>
                        </div> 
                    </div>
                    
                ))}
            </div>
        )

    }

    singleOption(e) {
        var current = JSON.parse(e.target.id);
        var qNumber = JSON.stringify(current[0]);
        var optionChecked = JSON.stringify(current[1]); 
        
        var optionNumber = questions[0].value.length;
        for (let i=0; i < optionNumber; i++) {
            var option = "[" + qNumber + "," + i + "]";
            document.getElementById(option).checked = false;
            document.getElementById(option).removeAttribute("checked");
        }

        var checkingOption = "[" + qNumber + "," + optionChecked + "]"
        document.getElementById(checkingOption).checked = true;
        document.getElementById(checkingOption).setAttribute("checked", "true");

    }

    calculate() {
      
        var finalscore = 0;
        var optionNumber = questions[0].value.length;
        var element, checking;
        var noBlank = 0;
        questions.map(item => {
            var questionNumber = questions.indexOf(item);
           
            for (let i=0; i < optionNumber; i++) {
                element = "[" + questionNumber + "," + i +"]";
                
                checking = document.getElementById(element).checked;

                if (checking === true) {
                    var value = parseInt(document.getElementById(element).value)
                    finalscore = finalscore + value;

                    noBlank = noBlank + 1;
                } 
            }
            
        })

        if (noBlank !== questions.length) {
            toast.error(`You must answer all questions !`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar : false
            });
        }
        else if (noBlank === questions.length) {
            toast.success(`Total score successfully calculated`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar : false
            });

            var interpretation = '';
            if (finalscore >9 && finalscore < 20) {
                interpretation = "Likely to be well"
            } else if (finalscore >19 && finalscore <25) {
                interpretation = "Likely to have a mild disorder"
            } else if (finalscore >24 && finalscore <30) {
                interpretation = "Likely to have a moderate disorder"
            } else if (finalscore >29 && finalscore <51) {
                interpretation = "Likely to have a severe disorder"
            }
            this.setState({score: finalscore})
            this.props.addData({ tool: 'K10', result: finalscore, interpretation: interpretation });
        }
      
    }

    cancel() {
        this.props.setState({tool: '', score: 'n/a'})
    }

    async save(e) {

        //save tool to mh_tool
        e.preventDefault();
        var newData = {};
   
        // axios post request
        newData['patientId'] = this.state.patientId;
        newData['userId'] = this.state.userId;
        newData['instrument'] = instrument;
        newData['score'] = this.state.score;

        if (newData.score >9 && newData.score < 20) {
            newData['notes'] = "Likely to be well"
        } else if (newData.score >19 && newData.score <25) {
            newData['notes'] = "Likely to have a mild disorder"
        } else if (newData.score >24 && newData.score <30) {
            newData['notes'] = "Likely to have a moderate disorder"
        } else if (newData.score >29 && newData.score <51) {
            newData['notes'] = "Likely to have a severe disorder"
        }

        //check if it's been calculated first
        if (newData.score === 'n/a') {
            toast.error("Need to calculate the score before saving")
        } else {

            //close this panel by updating tool, result, and notes state in parent component
            this.props.setState({tool: '', K10: [newData.score, newData.notes], score: 'n/a'})
        }

    }

    render() {
        var latestDate = this.state.latestDate;
     
        return (
            <div className="fixed top-0 left-0 h-full w-full z-10 bg-gray-900/80">
                <div id='tool' className="bg-white rounded p-6 xs:p-4 mx-auto  mt-20 xs:mt-0 h-4/5 w-8/12 xs:h-full xs:w-full">
                    <div className='title bg-sky-800 text-white text-lg -mt-6 w-2/5 xs:w-4/5 -ml-6 px-3  py-2' >{title}</div>
                    
                    <div className='content mt-4 h-4/5 xs:h-4/5 overflow-y-auto'>
                
                        {this.renderContent()}
                    </div>
                    <div className='mt-4 text-center'>
                    <div>
                        <button onClick={this.calculate} className='bg-yellow-600 focus:bg-yellow-700 font-semibold text-white p-2 w-32 xs:w-20 shadow-lg mx-2 rounded-lg'>Calculate</button>
                        { this.state.score === 'n/a' || this.state.tool === true ? (
                            <></>
                        ): (
                            <>
                            <button onClick={this.save} className='bg-sky-700 focus:bg-sky-800 font-semibold text-white p-2 w-32 xs:w-20 shadow-lg mx-2 rounded-lg'>Save</button>
                            </>
                        )}
                        { this.state.tool === true ? (
                            <>
                            <button onClick={this.printing} className=' bg-sky-700 focus:bg-sky-800 font-semibold text-white p-2 w-32 xs:w-20 shadow-lg mx-2 rounded-lg'>Print</button>
                            </>
                        ): (<></>)}
                        <button onClick={this.cancel} className='bg-red-700 focus:bg-red-800 font-semibold text-white p-2 w-32 xs:w-20 shadow-lg mx-2 rounded-lg'>Close</button>
                    </div>
                    <div className='score mt-2 xs:mt-1 font-semibold text-2xl xs:text-sm'>
                        Score : {this.state.score}
                        { latestDate ? (
                            <div className='text-sm'>last assessment saved on  <Moment format='DD MMMM YYYY'>{latestDate}</Moment></div>
                        ): (
                            <></>
                        )}
                    </div>
                    <div className='explanation invisible'><b>Notes</b> <p>{explanation}</p></div>
                    <div className='explanation invisible'><b>Developer</b> <p>{citation}</p></div>
                    <iframe id="CaredevoPrint" className='hidden'></iframe>
                    </div>

                </div>  
                <ToastContainer />
            </div>
        )

    }

};

export default K10;

