import React from 'react';
import Moment from 'react-moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const header = "In the past month, how much were you bothered by :";
const questions = [
    {type: "Reexperiencing", question: "Repeated, disturbing, and unwanted memories of the stressful experience? " , value: [0,1,2,3,4,] },
    {type: "Reexperiencing", question: "Repeated, disturbing dreams of the stressful experience? " , value: [0,1,2,3,4,] },
    {type: "Reexperiencing", question: "Suddenly feeling or acting as if the stressful experience were actually happening again (as if you were actually back there reliving it)? " , value: [0,1,2,3,4,] },
    {type: "Reexperiencing", question: "Feeling very upset when something reminded you of the stressful experience? " , value: [0,1,2,3,4,] },
    {type: "Reexperiencing", question: "Having strong physical reactions when something reminded you of the stressful experience (for example, heart pounding, trouble breathing, sweating)? " , value: [0,1,2,3,4,] },
    {type: "Avoidance", question: "Avoiding memories, thoughts, or feelings related to the stressful experience? " , value: [0,1,2,3,4,] },
    {type: "Avoidance", question: "Avoiding external reminders of the stressful experience (for example, people, places, conversations, activities, objects, or situations)? " , value: [0,1,2,3,4,] },
    {type: "Negative", question: "Trouble remembering important parts of the stressful experience?" , value: [0,1,2,3,4,] },
    {type: "Negative", question: "Having strong negative beliefs about yourself, other people, or the world (for example, having thoughts such as: I am bad, there is something seriously wrong with me,no one can be trusted, the world is completely dangerous)? " , value: [0,1,2,3,4,] },
    {type: "Negative", question: "Blaming yourself or someone else for the stressful experience or what happened after it?" , value: [0,1,2,3,4,] },
    {type: "Negative", question: "Having strong negative feelings such as fear, horror, anger, guilt, or shame? " , value: [0,1,2,3,4,] },
    {type: "Negative", question: "Loss of interest in activities that you used to enjoy? " , value: [0,1,2,3,4,] },
    {type: "Negative", question: "Feeling distant or cut off from other people? " , value: [0,1,2,3,4,] },
    {type: "Negative", question: "Trouble experiencing positive feelings (for example, being unable to feel happiness or have loving feelings for people close to you)? " , value: [0,1,2,3,4,] },
    {type: "Hyperarousal", question: "Irritable behavior, angry outbursts, or acting aggressively?" , value: [0,1,2,3,4,] },
    {type: "Hyperarousal", question: "Taking too many risks or doing things that could cause you harm?" , value: [0,1,2,3,4,] },
    {type: "Hyperarousal", question: "Being “superalert” or watchful or on guard?" , value: [0,1,2,3,4,] },
    {type: "Hyperarousal", question: "Feeling jumpy or easily startled?" , value: [0,1,2,3,4,] },
    {type: "Hyperarousal", question: "Having difficulty concentrating?" , value: [0,1,2,3,4,] },
    {type: "Hyperarousal", question: "Trouble falling or staying asleep?" , value: [0,1,2,3,4,] }

]

const explanation = `The PCL-5 is a self-report measure that is commonly used to assess posttraumatic stress disorder (PTSD) symptoms in adults. The PCL-5 stands for the "Posttraumatic Stress Disorder Checklist for DSM-5" and it is a 20-item questionnaire that assesses the severity of PTSD symptoms according to the diagnostic criteria in the DSM-5.

Each item on the PCL-5 is rated on a scale of 0 to 4, with higher scores indicating greater symptom severity. The total score on the PCL-5 can range from 0 to 80, with a higher score indicating a greater likelihood of a diagnosis of PTSD.

In general, a PCL-5 score of 33 or higher is considered to be a significant indicator of the presence of PTSD symptoms.

The PCL-5 (Posttraumatic Stress Disorder Checklist for DSM-5) was developed by Dr. Matthew J. Friedman, Dr. Terence M. Keane, and Dr. Frank W. Weathers, in collaboration with the National Center for PTSD, in the United States Department of Veterans Affairs. The PCL-5 is a self-report questionnaire used to screen for and assess symptoms of posttraumatic stress disorder (PTSD), and it is widely used in both clinical and research settings. The PCL-5 was first published in 2013 in the Journal of Traumatic Stress.`

const citation = `Weathers, F.W., Litz, B.T., Keane, T.M., Palmieri, P.A., Marx, B.P., & Schnurr, P.P. (2013). The PTSD Checklist for DSM-5 (PCL-5). Scale available from the National Center for PTSD at www.ptsd.va.gov.`

const title = "PCL-5 - PTSD Checklist";
const instrument = "PCL5";

class PCL5 extends React.Component {

    constructor(props) {
            super(props);
            this.state = {
                identity: localStorage.getItem("auth"),
                score: "n/a",
                latestDate:"",
                reexperiencing : 0,
                avoidance : 0,
                negative: 0,
                hyperarousal: 0,
                tool : props.tool
            };

            this.calculate = this.calculate.bind(this);
            this.save = this.save.bind(this);
            this.cancel = this.cancel.bind(this);

    };

 
    printing = () => {
        var htmlcode = document.getElementById('tool').innerHTML;
        var iframe = document.getElementById("CaredevoPrint");
        var printDocument = iframe.contentDocument;
        var printPreview = iframe.contentWindow;
        printPreview.focus();
     
        printDocument.open();
        printDocument.write(`<!DOCTYPE html>
        <html>
        <head>
        <link rel="stylesheet" type="text/css" media='print' href="/dist/print.css">
        </head>
        <style>

        body {
            line-height : 1.6;
            font-size : 12pt;
            padding-bottom  :50pt;
        }

        iframe {
            display : none;
        }

        input {
            outline : none;
            border : transparent;
        }

        @media print {        
            @page {
                size: A4;
                margin-top : 0.80cm;
                margin-bottom : 0.80cm;
            }

        * {
            -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
            color-adjust: exact !important;  /*Firefox*/
        }
        .section {
            page-break-inside: avoid;
            
        }

        .options {
            display : flex;
            flex-direction: row;
            margin-left : 20pt;
            margin-bottom : 10pt;
            border-bottom-style : solid;
            border-width: 0.5px;
            width: 490pt;
        }

        .optionblock {
            display : flex;
            flex-direction : row;
        }

        .qline {
            display : grid;
            grid-template-columns : 20pt 490pt;
           
        }

        .title {
            font-size:16pt;
            font-family: Arial, Helvetica, sans-serif;
            font-weight:600;
            text-align:center;
            padding : 10pt;
            width : 100%;
            height : 20pt;
            background-color: #42b3f5;
            background-image: linear-gradient(to right, #42b3f5 , white);
            border-radius: 5pt;
        }

        .score {
            text-align: center
        }
        .header {
            font-size : 13pt;
            color : black;
            font-weight : 600;
            margin-left : 10pt;
            margin-bottom : 20pt;
            margin-top : 20pt;
        }
        .explanation {
            margin-top : 10pt;
            margin-left : 10pt;
            white-space : pre-line;
            width: 480pt;
            text-align : justify;
            font-size : 10pt;
          
        }
        .button {
            display: none;
        }
        .decoration {
            position: fixed;
            font-family: Arial, Helvetica, sans-serif;
            font-weight:600;
            top:  300px;
            left : 100px;
            width : 300px;
            color : #42b3f5;
            opacity : 0.1;
            font-size : 140pt;
            letter-spacing : 2pt;
            z-index : -1;
        }
        
        </style>
        <body>`)
        printDocument.write(htmlcode);
        printDocument.write("<div class='decoration'>PCL5</div>");
        printDocument.write("</body></html>");
        printPreview.print();
        printPreview.close();
  
    }
    
    renderContent() {
        return (
            <div>
                <div className='header p-4 bg-gray-200 sticky top-0'>{header}</div>

                {questions.map((item, index) => (
                        <div className='section flex flex-col border-b-2' key={index}>
                        <div className='qline p-2 w-10/12 xs:w-auto flex flex-row gap-2'><div>{questions.indexOf(item)+1}.</div><div>{item.question}</div></div>
                        <div className='options flex flex-col options text-sm w-11/12 xs:w-auto ml-6'>
                            <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",0]" } value={item.value[0]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> Not at all</div>
                            <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",1]" } value={item.value[1]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> A little bit</div>
                            <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",2]" } value={item.value[2]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> Moderately</div>
                            <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",3]" } value={item.value[3]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> Quite a bit</div>
                            <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",4]" } value={item.value[4]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> Extremely</div>
                        </div> 
                    </div>
                    
                ))}
            </div>
        )

    }

    singleOption(e) {
        var current = JSON.parse(e.target.id);
        var qNumber = JSON.stringify(current[0]);
        var optionChecked = JSON.stringify(current[1]); 
        
        var optionNumber = questions[0].value.length;
        for (let i=0; i < optionNumber; i++) {
            var option = "[" + qNumber + "," + i + "]";
            document.getElementById(option).checked = false;
            document.getElementById(option).removeAttribute("checked");
        }

        var checkingOption = "[" + qNumber + "," + optionChecked + "]"
        document.getElementById(checkingOption).checked = true;
        document.getElementById(checkingOption).setAttribute("checked", "true");


    }

    calculate() {
      
        var finalscore = 0;
        var reexperiencing = 0;
        var avoidance = 0;
        var negative = 0;
        var hyperarousal = 0;

        var optionNumber = questions[0].value.length;
        var element, checking;
        var noBlank = 0;
        questions.map(item => {
            var questionNumber = questions.indexOf(item);
           
            for (let i=0; i < optionNumber; i++) {
                element = "[" + questionNumber + "," + i +"]";
                
                checking = document.getElementById(element).checked;

                if (checking === true) {
                    var value = parseInt(document.getElementById(element).value)
                    finalscore = finalscore + value;
               
                    if (item.type === 'Reexperiencing') {
                        reexperiencing = reexperiencing + value;
                    } else if (item.type === 'Avoidance') {
                        avoidance = avoidance + value;
                    } else if (item.type === 'Negative') {
                        negative = negative + value;
                    } else if (item.type === 'Hyperarousal') {
                        hyperarousal = hyperarousal + value;
                    }
                    noBlank = noBlank + 1;
                }
            }
            
        })

        if (noBlank !== questions.length) {
            toast.error(`You must answer all questions !`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar : false
            });
        }
        else if (noBlank === questions.length) {
            toast.success(`Total score successfully calculated`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar : false
            });
            this.setState({score: finalscore, reexperiencing: reexperiencing, avoidance: avoidance, negative: negative, hyperarousal: hyperarousal});
            var result = `Total ${finalscore}, with re-experiencing : ${reexperiencing}, avoidance : ${avoidance}, negative alterations in cognition and mood : ${negative}, hyperarousal : ${hyperarousal}`
            this.props.addData({ tool: 'PCL-5', result: result, interpretation: 'A score above 38 is a provisional diagnosis of PTSD.' });
        }
      
    }

    cancel() {
        this.props.setState({tool: '', score:'n/a'})
    }

    async save(e) {

        //save tool to mh_tool
        e.preventDefault();
        var newData = {};

   
        // axios post request
        newData['patientId'] = this.state.patientId;
        newData['userId'] = this.state.userId;
        newData['instrument'] = instrument;
        newData['score'] = this.state.score;
        newData['notes'] = `Re-expriencing: ${this.state.reexperiencing}. Avoidance: ${this.state.avoidance}. Negative alterations in cognition and mood: ${this.state.negative}. Hyperarousal: ${this.state.hyperarousal}.`
        
        //Additional notes
        var notes;
        if (newData.score > 37) {
            notes = " A score above 38 is a provisional diagnosis of PTSD."
            newData.notes = newData.notes + notes;
        }
        
        //check if it's been calculated first
        if (newData.score === 'n/a') {
            toast.error("Need to calculate the score before saving")
        } else {

            //close this panel by updating tool state in parent component
            this.props.setState({tool: '', PCL5: [newData.score, newData.notes], score:'n/a'})
        }

    }

    render() {
        var latestDate = this.state.latestDate;
     
        return (
            <div className="fixed top-0 left-0 h-full w-full z-10 bg-gray-900/80">
                <div id='tool' className=" bg-white h-4/5 rounded p-6 xs:p-4 mx-auto  mt-20 xs:mt-0 w-8/12 xs:h-full  xs:w-full">
                    <div className='title bg-sky-800 text-white text-lg -mt-6 w-2/5 xs:w-4/5  -ml-6 px-3  py-2' >{title}</div>
                   
                    <div className='content mt-4 h-4/5 xs:h-4/5 overflow-y-auto newpage'>
                
                        {this.renderContent()}
                    </div>
                    <div className='mt-4 text-center '>
                    <div>
                        <button onClick={this.calculate} className='bg-yellow-600 focus:bg-yellow-700 font-semibold text-white p-2 w-32 xs:w-20 shadow-lg mx-2 rounded-lg'>Calculate</button>
                        { this.state.score === 'n/a' || this.state.tool === true ? (
                            <></>
                        ): (
                            <>
                            <button onClick={this.save} className='bg-sky-700 focus:bg-sky-800 font-semibold text-white p-2 w-32 xs:w-20 shadow-lg mx-2 rounded-lg'>Save</button>
                            </>
                        )}
                        { this.state.tool === true ? (
                            <>
                            <button onClick={this.printing} className=' bg-sky-700 focus:bg-sky-800 font-semibold text-white p-2 w-32 xs:w-20 shadow-lg mx-2 rounded-lg'>Print</button>
                            </>
                        ): (<></>)}
                        <button onClick={this.cancel} className='bg-red-700 focus:bg-red-800 font-semibold text-white p-2 w-32 xs:w-20 shadow-lg mx-2 rounded-lg'>Close</button>
                    </div>
                    <div className='score mt-2 xs:mt-1 font-semibold text-2xl xs:text-sm'>
                        Score : {this.state.score}
                        <p className='text-xs'>Re-expriencing: {this.state.reexperiencing} &nbsp; Avoidance: {this.state.avoidance}</p>
                        <p className='text-xs'>Negative alterations in cognition and mood: {this.state.negative} &nbsp; Hyperarousal: {this.state.hyperarousal}</p>
                        { latestDate ? (
                            <div className='text-sm'>last assessment saved on  <Moment format='DD MMMM YYYY'>{latestDate}</Moment></div>
                        ): (
                            <></>
                        )}
                    </div>
                    <div className='explanation invisible'><b>Notes</b> <p>{explanation}</p></div>
                    <div className='explanation invisible'><b>Developer</b> <p>{citation}</p></div>
                    <iframe id="CaredevoPrint" className='hidden'></iframe>
                    </div>

                </div>  
                <ToastContainer />
            </div>
        )

    }

};

export default PCL5;

