import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import "./index.css";

// ReactDOM.render(
//     React.createElement(App, {}, null),
//     document.getElementById('react-target')
// );

ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById("root")
  );