import React from 'react';

const Navigation = () => {

    return (
        <nav className="sticky top-0 bg-slate-800 flex justify-between items-center z-50">
            <h1 className="text-white text-[30px] ml-4 font-semibold">Caredevo</h1>
     
            <div>
              
                {/* <button
                    onClick={() => window.location.href = 'https://www.caredevo.com'}
                    className="text-white px-4 py-2 m-2 transition duration-300 ease-in-out focus:outline-none focus:shadow-outline hover:bg-slate-700 rounded "
                >
                    Home
                </button> */}
            </div>
        </nav>
    );
}

export default Navigation;
