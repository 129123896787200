import React from 'react';
import Moment from 'react-moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const header = "How you have felt over the past week";
const questions = [
    {number: 1, question: "Are you basically satisfied with your life?" , value: [0,1] },
    {number: 2, question: "Have you dropped many of your activities and interests?" , value: [1,0] },
    {number: 3, question: "Do you feel that your life is empty? " , value: [1,0] },
    {number: 4, question: "Do you often get bored?" , value: [1,0] },
    {number: 5, question: "Are you in good spirits most of the time? " , value: [0,1] },
    {number: 6, question: "Are you afraid that something bad is going to happen to you?" , value: [1,0] },
    {number: 7, question: "Do you feel happy most of the time?" , value: [0,1] },
    {number: 8, question: "Do you often feel helpless?" , value: [1,0] },
    {number: 9, question: "Do you prefer to stay at home, rather than going out and doing new things?" , value: [1,0] },
    {number: 10, question: "Do you feel you have more problems with memory than most?" , value: [1,0] },
    {number: 11, question: "Do you think it is wonderful to be alive now?" , value: [0,1] },
    {number: 12, question: "Do you feel pretty worthless the way you are now?" , value: [1,0] },
    {number: 13, question: "Do you feel full of energy? " , value: [0,1] },
    {number: 14, question: "Do you feel that your situation is hopeless?" , value: [1,0] },
    {number: 15, question: "Do you think that most people are better off than you are?" , value: [1,0] },


]

const title = "Geriatric Depression Scale";
const instrument = "GDS";
const explanation = `The Geriatric Depression Scale (GDS) is a screening tool used to assess depression in older adults. It was developed in 1983 by J.A. Yesavage and colleagues to specifically address the unique symptoms of depression in older adults.

The GDS consists of a series of yes/no questions that evaluate different symptoms of depression, such as mood, feelings of worthlessness, loss of interest, and changes in sleep patterns. The questions are designed to be simple and easy to understand for older adults.

The GDS can be used by healthcare professionals to help diagnose depression in older adults, monitor the effectiveness of treatment, and track changes in symptoms over time. However, it is important to note that the GDS is a screening tool and should not be used as a definitive diagnosis of depression. A clinical evaluation by a healthcare professional is necessary to confirm a diagnosis of depression.`
const citation = `Brink TL, Yesavage JA, Lum O, Heersema P, Adey MB, Rose TL: Screening tests for geriatric depression. Clinical Gerontologist 1: 37-44, 1982.

Yesavage JA, Brink TL, Rose TL, Lum O, Huang V, Adey MB, Leirer VO: Development and validation of a geriatric depression screening scale: A preliminary report. Journal of Psychiatric Research 17: 37-49, 1983.

Sheikh JI, Yesavage JA: Geriatric Depression Scale (GDS): Recent evidence and development of a shorter version. Clinical Gerontology : A Guide to Assessment and Intervention 165-173, NY: The Haworth Press, 1986.

Sheikh JI, Yesavage JA, Brooks JO, III, Friedman LF, Gratzinger P, Hill RD, Zadeik A, Crook T: Proposed factor structure of the Geriatric Depression Scale. International Psychogeriatrics 3: 23-28, 1991.

The original scale is in the public domain due to it being funded by This work is funded by the National Institute of Aging and the Department of Veterans Affairs United States of America.`

class GDS extends React.Component {

    constructor(props) {
            super(props);
            this.state = {
                identity: localStorage.getItem("auth"),
                score: "n/a",
                latest:"",
                tool: props.tool
            };

            this.calculate = this.calculate.bind(this);
            this.save = this.save.bind(this);
            this.cancel = this.cancel.bind(this);

    };
    
    printing = () => {
        var htmlcode = document.getElementById('tool').innerHTML;
        var iframe = document.getElementById("CaredevoPrint");
        var printDocument = iframe.contentDocument;
        var printPreview = iframe.contentWindow;
        printPreview.focus();
     
        printDocument.open();
        printDocument.write(`<!DOCTYPE html>
        <html>
        <head>
        <link rel="stylesheet" type="text/css" media='print' href="/dist/print.css">
        </head>
        <style>

        body {
            line-height : 1.6;
            font-size : 12pt;
            padding-bottom  :50pt;
        }
        iframe {
            display : none;
        }

        input {
            outline : none;
            border : transparent;
        }
        @media print {        
            @page {
                size: A4;
                margin-top : 0.80cm;
                margin-bottom : 0.80cm;
            }

        * {
            -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
            color-adjust: exact !important;  /*Firefox*/
        }
        .section {
            page-break-inside: avoid;
            
        }

        .options {
            display : flex;
            flex-direction: row;
            margin-left : 20pt;
            margin-bottom : 10pt;
            border-bottom-style : solid;
            border-width: 0.5px;
            width: 490pt;
        }

        .optionblock {
            display : flex;
            flex-direction : row;
        }

        .qline {
            display : grid;
            grid-template-columns : 20pt 490pt;
           
        }
  
        .title {
            padding : 30pt
            width : 100%;
            height : 20pt;
            background-color: #42b3f5;
            background-image: linear-gradient(to right, #42b3f5 , white);
            border-radius: 5pt;
        }

        .title, .score {
            font-size:large;
            font-family: Arial, Helvetica, sans-serif;
            font-weight:600;
            color: black;
            padding: 10pt;
            text-align:center
        }
        .header {
            font-size : 13pt;
            color : black;
            font-weight : 600;
            margin-left : 10pt;
            margin-bottom : 20pt;
            margin-top : 20pt;
        }
        .explanation {
            margin-top : 10pt;
            margin-left : 20pt;
            white-space : pre-line;
            width: 450pt;
            text-align : justify;
            font-size : 10pt;
          
        }
        .button {
            display: none;
        }
        .decoration {
            position: fixed;
            font-family: Arial, Helvetica, sans-serif;
            font-weight:600;
            top:  300px;
            left : 100px;
            width : 300px;
            color : #42b3f5;
            opacity : 0.1;
            font-size : 140pt;
            letter-spacing : 2pt;
            z-index : -1;
        }
        </style>
        <body>`)
        printDocument.write(htmlcode);
        printDocument.write("<div class='decoration'>GDS</div>");
        printDocument.write("</body></html>");
        printPreview.print();
        printPreview.close();
  
    }

    
    renderContent() {

        return (
            <div>
                <div className='header p-4 bg-gray-200 sticky top-0'>{header}</div>
    
                {questions.map((item, index) => (
                    <div className='section flex flex-row xs:flex-col gap-4 xs:gap-0 border-b-2' key={index}>
                        <div className='qline p-2 w-10/12 xs:w-auto flex flex-row gap-2'><div>{questions.indexOf(item)+1}.</div><div>{item.question}</div></div>
                        <div className='options flex flex-row w-2/12 xs:ml-6 xs:w-auto'>
                            <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",0]" } value={item.value[0]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> Yes</div>
                            <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",1]" } value={item.value[1]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> No</div>
                        </div> 
                    </div>
                    
                ))}
            </div>
        )

    }

    singleOption(e) {
        var current = JSON.parse(e.target.id);
        var qNumber = JSON.stringify(current[0]);
        var optionChecked = JSON.stringify(current[1]); 
        
        var optionNumber = questions[0].value.length;
        for (let i=0; i < optionNumber; i++) {
            var option = "[" + qNumber + "," + i + "]";
            document.getElementById(option).checked = false;
            document.getElementById(option).removeAttribute("checked");
        }

        var checkingOption = "[" + qNumber + "," + optionChecked + "]"
        document.getElementById(checkingOption).checked = true;
        document.getElementById(checkingOption).setAttribute("checked", "true");

    }

    calculate() {
      
        var finalscore = 0;
        var optionNumber = questions[0].value.length;
        var element, checking;
        var noBlank = 0;
        questions.map(item => {
            var questionNumber = questions.indexOf(item);
           
            for (let i=0; i < optionNumber; i++) {
                element = "[" + questionNumber + "," + i +"]";
                
                checking = document.getElementById(element).checked;

                if (checking === true) {
                    var value = parseInt(document.getElementById(element).value)
                    finalscore = finalscore + value;

                    noBlank = noBlank + 1;
                }
            }
            
        })

        if (noBlank !== questions.length) {
            toast.error(`You must answer all questions !`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar : false
            });
        }
        else if (noBlank === questions.length) {
            toast.success(`Total score successfully calculated`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar : false
            });
            var interpretation = '';
            if (finalscore < 5) {
                interpretation = "Normal"
            } else if (finalscore > 4 && finalscore <9) {
                interpretation  = "Mild depression"
            } else if (finalscore > 8 && finalscore <12) {
                interpretation  = "Moderate depression"
            } else if (finalscore > 11) {
                interpretation  = "Severe depression"
            }
            this.setState({score: finalscore})
            this.props.addData({ tool: 'Geriatric Depression Scale', result: finalscore, interpretation: interpretation });
        }
      
    }

    cancel() {
        this.props.setState({tool: '', score: 'n/a'})
    }

    async save(e) {

        //save tool to mh_tool
        e.preventDefault();
        var newData = {};
   
        // axios post request
        newData['patientId'] = this.state.patientId;
        newData['userId'] = this.state.userId;
        newData['instrument'] = instrument;
        newData['score'] = this.state.score;

        if (newData.score < 5) {
            newData['notes'] = "Normal"
        } else if (newData.score > 4 && newData.score <9) {
            newData['notes'] = "Mild depression"
        } else if (newData.score > 8 && newData.score <12) {
            newData['notes'] = "Moderate depression"
        } else if (newData.score > 11) {
            newData['notes'] = "Severe depression"
        }

        //check if it's been calculated first
        if (newData.score === 'n/a') {
            toast.error("Need to calculate the score before saving")
        } else {

            //close this panel by updating tool state in parent component
            this.props.setState({tool: '', GDS : [newData.score, newData.notes], score: 'n/a'})
        }

    }

    render() {
        var latestDate = this.state.latestDate;
     
        return (
            <div className="fixed top-0 left-0 h-full w-full z-10 bg-gray-900/80">
                <div id='tool' className="bg-white rounded p-6 xs:p-4 mx-auto  mt-20 xs:mt-0 h-4/5 w-8/12 xs:h-full xs:w-full">
                    <div className='title bg-sky-800 text-white text-lg -mt-6 w-2/5 xs:w-4/5 -ml-6 px-3  py-2' >{title}</div>
                    <div className='content mt-4 h-4/5 xs:h-4/5 overflow-y-auto'>
                
                        {this.renderContent()}
                    </div>
                    <div className='mt-4 text-center'>
                    <div>
                        <button onClick={this.calculate} className='bg-yellow-600 focus:bg-yellow-700 font-semibold text-white p-2 w-32 xs:w-20 shadow-lg mx-2 rounded-lg'>Calculate</button>
                        { this.state.score === 'n/a' || this.state.tool === true ? (
                            <></>
                        ): (
                            <>
                            <button onClick={this.save} className='bg-sky-700 focus:bg-sky-800 font-semibold text-white p-2 w-32 xs:w-20 shadow-lg mx-2 rounded-lg'>Save</button>
                            </>
                        )}
                        { this.state.tool === true ? (
                            <>
                            <button onClick={this.printing} className=' bg-sky-700 focus:bg-sky-800 font-semibold text-white p-2 w-32 xs:w-20 shadow-lg mx-2 rounded-lg'>Print</button>
                            </>
                        ): (<></>)}
                        <button onClick={this.cancel} className='bg-red-700 focus:bg-red-800 font-semibold text-white p-2 w-32 xs:w-20 shadow-lg mx-2 rounded-lg'>Close</button>
                    </div>
                    <div className='score mt-2 xs:mt-1 font-semibold text-2xl xs:text-lg'>
                        Score : {this.state.score}
                        { latestDate ? (
                            <div className='text-sm'>last assessment saved on  <Moment format='DD MMMM YYYY'>{latestDate}</Moment></div>
                        ): (
                            <></>
                        )}
                    </div>
                    <div className='explanation invisible'><b>Notes</b> <p>{explanation}</p></div>
                    <div className='explanation invisible'><b>Developer</b> <p>{citation}</p></div>
                    <iframe id="CaredevoPrint" className='hidden'></iframe>
                    </div>

                </div>  
                <ToastContainer />
            </div>
        )

    }

};

export default GDS;

