import React from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//tools
import K10 from './MetricComponents/psychometric/k10';
import PSC from './MetricComponents/psychometric/PSC';
import ACE from './MetricComponents/psychometric/ACE';
import PCL5 from './MetricComponents/psychometric/PCL5';
import DASS21 from './MetricComponents/psychometric/DASS21';
import ASRS from './MetricComponents/psychometric/ASRS';
import VADRS from './MetricComponents/psychometric/VADRS';
import AQ from './MetricComponents/psychometric/AQ';
import GDS from './MetricComponents/psychometric/GDS';
// import EPDS from './tools/EPDS';
// import EDEQ from './tools/EDEQ';

const formatDate = (dateString) => {
    const date = new Date(dateString);
  
    let day = date.getDate().toString();
    let month = (date.getMonth() + 1).toString(); // Months are zero-based
    let year = date.getFullYear();
  
    // Add leading zero to day and month if they are less than 10
    day = day.length < 2 ? '0' + day : day;
    month = month.length < 2 ? '0' + month : month;
  
    return `${day}/${month}/${year}`;
};

function convertToInputDateFormat(dateString) {
    const date = new Date(dateString);
    
    // Extracting year, month, and date
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // +1 because months are 0-indexed
    const day = String(date.getUTCDate()).padStart(2, '0');
    
    return `${year}-${month}-${day}`;
}

const toolList = [
    { label: "K-10", tool: 'K10', subtitle: 'Kessler Distress Scale', note:"For initial check and monitoring of level of distress in adult."},
    { label: "DASS-21", tool: "DASS21", subtitle : "Depression Anxiety Stress Scale", note:"Initial and monitoring level of depression, anxiety and stress in adult."},
    { label: "GDS", tool: "GDS", subtitle : "Geriatric Depression Scale", note:"To be used for older adult above 65 years old to measure level of depression."},
    { label: "PSC", tool: "PSC", subtitle : "Paediatric Symptoms Checklist", note:"Identify children with behaviour and emotional difficulties."},
    { label: "PCL-5", tool: "PCL5", subtitle : "PTSD checklist", note:"For initial and monitoring adult with PTSD symptoms."},
    { label: "ASRS v1.1", tool: "ASRS", subtitle : "Adult ADHD Self Report Scale", note:"Screening tool for adult with possible ADHD symptoms"},
    { label: "VADRS", tool: "VADRS", subtitle : "Vanderbilt ADHD Diagnostic Rating Scale", note:"Screening tool for childe age 6 to 12 with possible ADHD and other related comorbid symptoms"},
    { label: "AQ score", tool: "AQ", subtitle : "Adult Autism Quotient Score", note:"To check possibility of adult with autism symptoms"},
    { label: "ACE", tool: "ACE", subtitle : "Adverse Childhood Event", note:"Measure burden of significant childhood event"},
    // { label: "EDE-Q", tool: "EDEQ", subtitle : "Eating Disorder Examination Questionnaire ", note:"Diagnostic tool for Eating Disorder"},
    // { label: "EPDS", tool: "EPDS", subtitle : "Edinburgh Postnatal Depression Scale", note:"Check symptoms of depression for pre and post partum"}
]

const EndPoint = process.env.REACT_APP_API_ENDPOINT;

class Tools extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tool : "", 
            metric : [],
            requestTool: [],
            token: '',
            formData: {
                lastName: '',
                firstName: '',
                dob: ''
            },
      
        };
        this.displayTools = this.displayTools.bind(this);
    };

    componentDidMount() {
        var queryString = window.location.search;
        if (!queryString) {
            this.setState({requestTool: []})
        } else {
            // Replace "&amp;" with "&"
            var queryString = queryString.replace(/&amp;/g, '&');
            var urlParams = new URLSearchParams(queryString);
            var paramValue = urlParams.get('metric');
            const list = paramValue.split(',').map(item => item.trim());
            this.setState({requestTool: list})

            //get token 
            var tokenValue = urlParams.get('sender');
            this.setState({token: tokenValue});
        }
    }

    displayTools = (e) => {
        this.setState({tool: e.target.id})
    }

    // Function to add data to the metric list
    addDataToMetric = (data) => {
        // Check if the tool already exists in the metric list
        const index = this.state.metric.findIndex(item => item.tool === data.tool);
    
        if (index !== -1) {
            // If the tool already exists, replace the existing data with the new data
            this.setState(prevState => ({
                metric: prevState.metric.map((item, idx) => idx === index ? data : item)
            }));
        } else {
            // If the tool doesn't exist, add the new data to the list
            this.setState(prevState => ({
                metric: [...prevState.metric, data]
            }));
        }
    }
    

    renderTool() {
        var requestTool = this.state.requestTool;
        
        if (requestTool) {
            return toolList.map((tx, index) => {
            
                if (requestTool.includes(tx.tool)) {
            
                    return (
                    <div className="grid grid-cols-2 w-96 h-40 relative bg-slate-100 border border-slate-200 shadow-lg rounded-lg" key={index}>
                        <div className="p-4 text-left text-gray-800 text-3xl relative">
                            <p className='py-1 font-semibold'>{tx.label}</p>
                            <hr></hr>
                            <p className='text-sm mt-2 font-light white'>{tx.subtitle}</p>
                    
                        </div>
                        <div className='text-sm p-4'>{tx.note}
                        <div>

                            <button onClick={this.displayTools} id={tx.tool} className='bg-sky-700 bottom-3 right-3 absolute hover:bg-sky-800 w-36 text-white px-4 py-2 rounded-lg shadow-xl'>Open</button>
                        </div>
                        </div>
                    </div>
                    )
                } 
            })
        }  
    }

    renderResult() {
        var metric = this.state.metric;
        
        return metric.map(tx => {
            return (
                <tr>
                    <td className="p-2">{tx.tool}</td>
                    <td className="p-2">{tx.result}</td>
                    <td className="p-2">{tx.interpretation}</td>
                </tr>
                 
            );
        });
        
    }

    sendResult = async() => {
        var metric = this.state.metric;
        var tool = this.state.requestTool;
        var today = new Date();
        var token = this.state.token;
        var patient = this.state.formData;
        
        // // check if all tools have been done
        if (metric.length !== tool.length) {
            toast.error(`You have not done all tools yet.`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar : false
            });
            return;
        }

        if (patient.firstName === "") {
            toast.error(`You need to input your first name`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar : false
            });
            return;
        }

        if (patient.lastName === "") {
            toast.error(`You need to input your last name`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar : false
            });
            return;
        }

        if (patient.dob === '') {
            toast.error(`You need to input your date of birth`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar : false
            });
            return;
        }

        //send result
        try {
            const response = await axios.post(`${EndPoint}/user/sendresult`,{
                result : metric,
                date : formatDate(today),
                firstName : patient.firstName,
                lastName : patient.lastName,
                dob : formatDate(patient.dob),
                token : token
            });
            this.setState({ userData: response.data });
            toast.success(`Your results have been seen to your practitioner`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar : false
            });
          } catch (error) {
            toast.error(`Error sending results`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar : false
            });
            // Handle errors here
          }

    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState(prevState => ({
            formData: {
                ...prevState.formData,
                [name]: value,
            },
        }));
    };


    render() {

        var tool = this.state.tool;
        var requestTool = this.state.requestTool;
        const formData = this.state.formData;
        const dob = convertToInputDateFormat(formData.dob);
     
            return (
                <>
                <div className='text-center text-gray-900 text-2xl xs:text-xl font-semibold px-10 py-4'>Psychometric tool.</div>
                
                { requestTool.length > 0 ? (
                
                    <div className='text-center text-gray-900 text-md'>Please fill up below psychometric tools requested by your medical practitioner.
                    <p>Click calculate to finalise the score.</p>
                    <p>Then click send result to send your testing result to your medical practitioner.</p>
                    </div>
                    
                ):(
                    <>
                    <div className='text-center text-gray-900 text-md'>Please contact your medical practitioner to request which tool do you need to use.</div>
                    </>
                )}
       
                <div className="flex flex-row flex-wrap justify-center mx-4 my-4 xs:mx-1 xs:my-2 mb-10 xs:mb-6 gap-4 xs:gap-4">
             
                    {this.renderTool()}
                
                </div>
                {(() => { 
                    switch (tool) {
                        case "PSC" :
                            return (
                                <PSC
                                tool={true}
                                ref={ref => (this.child = ref)}
                                setState={state => this.setState(state)}
                                addData={this.addDataToMetric} 
                                />
                            )
                        case "K10":
                            return (
                                <K10 
                                tool={true}
                                ref={ref => (this.child = ref)}
                                setState={state => this.setState(state)}
                                addData={this.addDataToMetric} 
                                />
                            )
                        case "ACE":
                            return (
                                <ACE
                                tool={true}
                                ref={ref => (this.child = ref)}
                                setState={state => this.setState(state)}
                                addData={this.addDataToMetric} 
                                />
                            )
                        case "PCL5":
                            return (
                                <PCL5
                                tool={true}
                                ref={ref => (this.child = ref)}
                                setState={state => this.setState(state)}
                                addData={this.addDataToMetric} 
                                />
                            )
                        case "DASS21":
                            return (
                                <DASS21
                                tool={true}
                                ref={ref => (this.child = ref)}
                                setState={state => this.setState(state)}
                                addData={this.addDataToMetric} 
                                />
                            )
                        case "ASRS":
                            return (
                                <ASRS
                                tool={true}
                                ref={ref => (this.child = ref)}
                                setState={state => this.setState(state)}
                                addData={this.addDataToMetric} 
                                />
                            )
                        case "VADRS":
                            return (
                                <VADRS
                                tool={true}
                                ref={ref => (this.child = ref)}
                                setState={state => this.setState(state)}
                                addData={this.addDataToMetric} 
                                />
                            )
                        case "AQ":
                            return (
                                <AQ
                                tool={true}
                                ref={ref => (this.child = ref)}
                                setState={state => this.setState(state)}
                                addData={this.addDataToMetric} 
                                />
                            )
                        case "GDS":
                            return (
                                <GDS
                                tool={true}
                                ref={ref => (this.child = ref)}
                                setState={state => this.setState(state)}
                                addData={this.addDataToMetric} 
                                />
                            )
                        case "EPDS":
                            return (
                                <EPDS
                                tool={true}
                                ref={ref => (this.child = ref)}
                                setState={state => this.setState(state)}
                                addData={this.addDataToMetric} 
                                />
                            )
                        case "EDEQ":
                            return (
                                <EDEQ
                                tool={true}
                                ref={ref => (this.child = ref)}
                                setState={state => this.setState(state)}
                                addData={this.addDataToMetric} 
                                />
                            )
                            
                        default:
                            return null    
                    }

                })()}

                <div className='text-center p-4 mx-auto bg-slate-100 rounded shadow-lg w-auto'>
                    <div className='flex flex-row gap-4 justify-center mb-4 xs:flex-col'>
                        <div>
                            <label className="text-xs">First Name</label>
                            <input
                                type="text"
                                name="firstName"
                                value={formData.firstName}
                                onChange={this.handleChange}
                                className="border border-slate-200 p-1 m-1 text-xs w-40"
                            />
                        </div>
                        <div>
                            <label className="text-xs">Last Name</label>
                            <input
                                type="text"
                                name="lastName"
                                value={formData.lastName}
                                onChange={this.handleChange}
                                className="border border-slate-200 p-1 m-1 text-xs w-40"
                            />
                        </div>
                        <div>
                            <label className="text-xs">Date of Birth</label>
                            <input
                                type="date"
                                name="dob"
                                value={dob}
                                onChange={this.handleChange}
                                className="border border-slate-200 p-1 m-1 text-xs w-40"
                            />
                        </div>
                    </div>
                    <table className='w-full'>
                        <thead>
                            <tr className='bg-sky-800 text-white text-sm font-thin'>
                                <th className="p-2">Tool</th>
                                <th className="p-2">Result</th>
                                <th className="p-2">Interpretation</th>
                            </tr>
                        </thead>
                        <tbody>
                        {this.renderResult()}
                        </tbody>
                    </table>
                    <div><button onClick={this.sendResult} className='p-2 m-4 w-42 bg-sky-800 hover:bg-sky-900 text-white rounded shadow-lg'>Send Result</button></div>
                </div>
                <ToastContainer />
                </>
            )
    }

}

export default Tools;