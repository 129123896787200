import React from 'react';
import Moment from 'react-moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//const header = ["No", "How strongly that statement applies to you", "Definitely Agree", "Slightly Agree", "Slightly Disagree", "Definitely Disagree"];
const header = "How strongly this statement applies to you";
const questions = [
    {number: "1", question: "I prefer to do things with others rather than on my own." , value: [0,0,1,1] },
    {number: "2", question: "I prefer to do things the same way over and over again." , value: [1,1,0,0] },
    {number: "3", question: "If I try to imagine something, I find it very easy to create a picture in my mind." , value: [0,0,1,1] },
    {number: "4", question: "I frequently get so strongly absorbed in one thing that I lose sight of other things." , value: [1,1,0,0,] },
    {number: "5", question: "I often notice small sounds when others do not." , value: [1,1,0,0] },
    {number: "6", question: "I usually notice car number plates or similar strings of information." , value: [1,1,0,0] },
    {number: "7", question: "Other people frequently tell me that what I’ve said is impolite, even though I think it is polite." , value: [1,1,0,0] },
    {number: "8", question: "When I’m reading a story, I can easily imagine what the characters might look like." , value: [0,0,1,1] },
    {number: "9", question: "I am fascinated by dates." , value: [1,1,0,0] },
    {number: "10", question: "In a social group, I can easily keep track of several different people’s conversations." , value: [0,0,1,1] },
    {number: "11", question: "I find social situations easy." , value: [1,1,0,0] },
    {number: "12", question: "I tend to notice details that others do not." , value: [1,1,0,0] },
    {number: "13", question: "I would rather go to a library than to a party." , value: [1,1,0,0] },
    {number: "14", question: "I find making up stories easy." , value: [0,0,1,1] },
    {number: "15", question: "I find myself drawn more strongly to people than to things." , value: [0,0,1,1] },
    {number: "16", question: "I tend to have very strong interests, which I get upset about if I can’t pursue." , value: [1,1,0,0] },
    {number: "17", question: "I enjoy social chitchat." , value: [0,0,1,1] },
    {number: "18", question: "When I talk, it isn’t always easy for others to get a word in edgewise." , value: [1,1,0,0] },
    {number: "19", question: "I am fascinated by numbers." , value: [1,1,0,0] },
    {number: "20", question: "When I’m reading a story, I find it difficult to work out the characters’ intentions." , value: [1,1,0,0] },
    {number: "21", question: "I don’t particularly enjoy reading fiction." , value: [1,1,0,0] },
    {number: "22", question: "I find it hard to make new friends." , value: [1,1,0,0] },
    {number: "23", question: "I notice patterns in things all the time." , value: [1,1,0,0] },
    {number: "24", question: "I would rather go to the theater than to a museum." , value: [0,0,1,1] },
    {number: "25", question: "It does not upset me if my daily routine is disturbed." , value: [0,0,1,1] },
    {number: "26", question: "I frequently find that I don’t know how to keep a conversation going." , value: [1,1,0,0] },
    {number: "27", question: "I find it easy to “read between the lines” when someone is talking to me." , value: [0,0,1,1] },
    {number: "28", question: "I usually concentrate more on the whole picture, rather than on the small details." , value: [0,0,1,1] },
    {number: "29", question: "I am not very good at remembering phone numbers." , value: [0,0,1,1] },
    {number: "30", question: "I don’t usually notice small changes in a situation or a person’s appearance." , value: [0,0,1,1] },
    {number: "31", question: "I know how to tell if someone listening to me is getting bored." , value: [0,0,1,1] },
    {number: "32", question: "I find it easy to do more than one thing at once." , value: [0,0,1,1] },
    {number: "33", question: "When I talk on the phone, I’m not sure when it’s my turn to speak." , value: [1,1,0,0] },
    {number: "34", question: "I enjoy doing things spontaneously." , value: [0,0,1,1] },
    {number: "35", question: "I am often the last to understand the point of a joke." , value: [1,1,0,0] },
    {number: "36", question: "I find it easy to work out what someone is thinking or feeling just by looking at their face." , value: [0,0,1,1] },
    {number: "37", question: "If there is an interruption, I can switch back to what I was doing very quickly." , value: [0,0,1,1] },
    {number: "38", question: "I am good at social chitchat." , value: [0,0,1,1] },
    {number: "39", question: "People often tell me that I keep going on and on about the same thing." , value: [1,1,0,0] },
    {number: "40", question: "When I was young, I used to enjoy playing games involving pretending with other children." , value: [0,0,1,1] },
    {number: "41", question: "I like to collect information about categories of things (e.g., types of cars, birds, trains, plants)." , value: [1,1,0,0] },
    {number: "42", question: "I find it difficult to imagine what it would be like to be someone else." , value: [1,1,0,0] },
    {number: "43", question: "I like to carefully plan any activities I participate in." , value: [1,1,0,0] },
    {number: "44", question: "I enjoy social occasions." , value: [0,0,1,1] },
    {number: "45", question: "I find it difficult to work out people’s intentions." , value: [1,1,0,0] },
    {number: "46", question: "New situations make me anxious." , value: [1,1,0,0] },
    {number: "47", question: "I enjoy meeting new people." , value: [0,0,1,1] },
    {number: "48", question: "I am a good diplomat." , value: [0,0,1,1] },
    {number: "49", question: "I am not very good at remembering people’s date of birth." , value: [0,0,1,1] },
    {number: "50", question: "I find it very easy to play games with children that involve pretending." , value: [0,0,1,1] }
]

const explanation = `
The Autism Quotient (AQ) is a self-report questionnaire that is used to measure the presence of autistic traits in individuals without necessarily diagnosing autism. The AQ was developed by Simon Baron-Cohen and his colleagues in 2001 and consists of 50 statements that assess social skills, communication, attention to detail, and imagination.

Each statement on the AQ is rated on a 4-point scale, with higher scores indicating a greater degree of autistic traits. The total score on the AQ can range from 0 to 50, with a score of 32 or higher being typically considered as indicative of a clinically significant level of autistic traits.

However, it is important to note that the AQ is not a diagnostic tool for autism spectrum disorder (ASD) and should not be used to diagnose or rule out autism. A diagnosis of ASD requires a comprehensive clinical assessment that includes evaluation of developmental history, social communication skills, restricted and repetitive behaviors, and other factors that may be indicative of ASD.`

const title = "Autism Quotient Score";
const instrument = "AQ";
const citation = `Baron-Cohen, S., Wheelwright, S., Skinner, R., Martin, J., & Clubley, E. (2001). The autism-spectrum quotient (AQ): Evidence from asperger syndrome/high-functioning autism, males and females, scientists and mathematicians. Journal of autism and developmental disorders, 31(1), 5-17.

This test can only be used for professional, scientific or clinical purposes, and not for commercial use. Use of these Tests for commercial purposes violates copyright.

Copyright © Autism Research Centre, 
University of Cambridge`


class AQ extends React.Component {

    constructor(props) {
            super(props);
            this.state = {
                identity: localStorage.getItem("auth"),
                score: "n/a",
                latest:"",
                tool : props.tool
            };

            this.calculate = this.calculate.bind(this);
            this.save = this.save.bind(this);
            this.cancel = this.cancel.bind(this);

    };

    printing = () => {
        var htmlcode = document.getElementById('tool').innerHTML;
        var iframe = document.getElementById("CaredevoPrint");
        var printDocument = iframe.contentDocument;
        var printPreview = iframe.contentWindow;
        printPreview.focus();
     
        printDocument.open();
        printDocument.write(`<!DOCTYPE html>
        <html>
        <head>
        <link rel="stylesheet" type="text/css" media='print' href="/dist/print.css">
        </head>
        <style>

        body {
            line-height : 1.6;
            font-size : 12pt;
            padding-bottom  :50pt;
        }
        iframe {
            display : none;
        }
        
        @media print {        
            @page {
                size: A4;
                margin-top : 0.80cm;
                margin-bottom : 0.80cm;
            }
            
        * {
            -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
            color-adjust: exact !important;  /*Firefox*/
        }
        .section {
            page-break-inside: avoid;
        }
        .options {
            display : flex;
            flex-direction: row;
            margin-left : 20pt;
            margin-bottom : 10pt;
            border-bottom-style : solid;
            border-width: 0.5px;
            width: 490pt;
        }

        .qline {
            display : grid;
            grid-template-columns : 20pt 490pt;
           
        }

        .title {
            padding : 30pt
            width : 100%;
            height : 20pt;
            background-color: #42b3f5;
            background-image: linear-gradient(to right, #42b3f5 , white);
            border-radius: 5pt;

        }

        .title, .score {
            font-size:large;
            font-family: Arial, Helvetica, sans-serif;
            font-weight:600;
            color: black;
            padding: 10px;
            text-align:center
        }
        .header {
            font-size : 13pt;
            color : black;
            font-weight : 600;
            margin-left : 10pt;
            margin-bottom : 20pt;
            margin-top : 20pt;
        }
        .explanation {
            margin-top : 10pt;
            margin-left : 20pt;
            white-space : pre-line;
            width: 450pt;
            text-align : justify;
            font-size : 10pt;
          
        }
        .button {
            display: none;
        }
        .decoration {
            position: fixed;
            font-family: Arial, Helvetica, sans-serif;
            font-weight:600;
            top:  300px;
            left : 100px;
            width : 300px;
            color : #42b3f5;
            opacity : 0.1;
            font-size : 140pt;
            letter-spacing : 2pt;
            z-index : -1;
        }
        </style>
        <body>`)
        printDocument.write(htmlcode);
        printDocument.write("<div class='decoration'>AQ</div>")
        printDocument.write("</body></html>");
        printPreview.print();
        printPreview.close();
  
    }

    renderContent() {
        return (
            <div>
                <div className='header p-4 bg-gray-200 sticky top-0'>{header}</div>
    
                {questions.map((item, index) => (
                    <div className='section flex flex-col border-b-2' key={index}>
                    <div className='qline p-2 w-11/12 xs:w-auto flex flex-row gap-2'><div>{questions.indexOf(item)+1}.</div><div>{item.question}</div></div>
                    <div className='options flex flex-row xs:flex-col w-11/12 xs:w-auto ml-6'>
                        <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",0]" } value={item.value[0]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> Definitely agree</div>
                        <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",1]" } value={item.value[1]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> Slightly agree</div>
                        <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",2]" } value={item.value[2]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> Slightly disagree</div>
                        <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",3]" } value={item.value[3]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> Definitely disagree</div>
                    </div>
                    </div>
                ))}
            </div>
        )

    }

    singleOption(e) {
        var current = JSON.parse(e.target.id);
        var qNumber = JSON.stringify(current[0]);
        var optionChecked = JSON.stringify(current[1]); 
        
        var optionNumber = questions[0].value.length;
        for (let i=0; i < optionNumber; i++) {
            var option = "[" + qNumber + "," + i + "]";
            document.getElementById(option).checked = false;
            document.getElementById(option).removeAttribute("checked");
        }

        var checkingOption = "[" + qNumber + "," + optionChecked + "]"
        document.getElementById(checkingOption).checked = true;
        document.getElementById(checkingOption).setAttribute("checked", "true");


    }

    calculate() {
      
        var finalscore = 0;
        var optionNumber = questions[0].value.length;
        var element, checking;
        var noBlank = 0;
        questions.map(item => {
            var questionNumber = questions.indexOf(item);
           
            for (let i=0; i < optionNumber; i++) {
                element = "[" + questionNumber + "," + i +"]";
                
                checking = document.getElementById(element).checked;

                if (checking === true) {
                    var value = parseInt(document.getElementById(element).value)
                    finalscore = finalscore + value;

                    noBlank = noBlank + 1;
                }
            }
            
        })

        if (noBlank !== questions.length) {
            toast.error(`You must answer all questions !`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar : false
            });
        }
        else if (noBlank === questions.length) {
            toast.success(`Total score successfully calculated`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar : false
            });
            this.setState({score: finalscore});
            this.props.addData({ tool: 'Autism Quotient', result: finalscore, interpretation: 'Score of 29 or higher being typically considered as indicative of a clinically significant level of autistic traits' });
        }
      
    }

    cancel() {
        this.props.setState({tool: '', score: 'n/a'})
    }

    async save(e) {

        //save tool to mh_tool
        e.preventDefault();
        var newData = {};
   
        // axios post request
        newData['patientId'] = this.state.patientId;
        newData['userId'] = this.state.userId;
        newData['instrument'] = instrument;
        newData['score'] = this.state.score;

        if (newData.score > 28) {
            newData['notes'] = "Score of 29 or more is indicative of clinically significant autism traits."
        } else {
            newData['notes'] = "Score less then 29 is not indicative of autism traits."
        }

        //check if it's been calculated first
        if (newData.score === 'n/a') {
            toast.error("Need to calculate the score before saving")
        } else {

            //close this panel by updating tool state in parent component
            this.props.setState({tool: '', AQ: [newData.score, newData.notes], score: 'n/a'})
        }

    }

    render() {
        var latestDate = this.state.latestDate;
     
        return (
            <div className="fixed top-0 left-0 h-full w-full z-10 bg-gray-900/80">
                <div  id='tool' className="h-4/5 bg-white rounded p-6 xs:p-4 mx-auto  mt-20 xs:mt-0 w-8/12 xs:h-full xs:w-full">
                    <div className='title bg-sky-800 text-white text-md -mt-6 w-2/5 xs:w-4/5 -ml-6 px-3  py-2' >{title}</div>
                    <div className='content mt-4 h-3/4 xs:h-3/4 overflow-y-auto'>
                
                        {this.renderContent()}
                    </div>
                    <div className='mt-4 text-center'>
                    <div>
                        <button onClick={this.calculate} className='bg-yellow-600 focus:bg-yellow-700 font-semibold text-white p-2 w-32 xs:w-20 shadow-lg mx-2 rounded-lg'>Calculate</button>
                        { this.state.score === 'n/a' || this.state.tool === true ? (
                            <></>
                        ): (
                            <>
                            <button onClick={this.save} className='bg-sky-700 focus:bg-sky-800 font-semibold text-white p-2 w-32 xs:w-20 shadow-lg mx-2 rounded-lg'>Save</button>
                            </>
                        )}
                        { this.state.tool === true ? (
                            <>
                            <button onClick={this.printing} className=' bg-sky-700 focus:bg-sky-800 font-semibold text-white p-2 w-32 xs:w-20 shadow-lg mx-2 rounded-lg'>Print</button>
                            </>
                        ): (<></>)}
                        <button onClick={this.cancel} className='bg-red-700 focus:bg-red-800 font-semibold text-white p-2 w-32 xs:w-20 shadow-lg mx-2 rounded-lg'>Close</button>
                    </div>
                    <div className='score mt-2 xs:mt-1 font-semibold text-2xl xs:text-lg'>
                        Score : {this.state.score}
                        { latestDate ? (
                            <div className='text-sm'>last assessment saved on  <Moment format='DD MMMM YYYY'>{latestDate}</Moment></div>
                        ): (
                            <></>
                        )}
                    </div>
                    <div className='explanation invisible newpage'><b>Explanation</b> <p>{explanation}</p></div>
                    <iframe id="CaredevoPrint" className='hidden'></iframe>
                    </div>

                </div>  
                <ToastContainer />
            </div>
        )

    }


};

export default AQ;

