import React from 'react';
import Moment from 'react-moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const header = "How you fell over the past 6 months";
const questions = [
    {type: "A", subset: "Inattentive", question: "How often do you have trouble wrapping up the final details of a project, once the challenging parts have been done?" , value: [0,0,1,1,1] },
    {type: "A", subset: "Inattentive", question: "How often do you have difficulty getting things in order when you have to do a task that requires organization?" , value: [0,0,1,1,1] },
    {type: "A", subset: "Inattentive", question: "How often do you have problems remembering appointments or obligations?" , value: [0,0,1,1,1] },
    {type: "A", subset: "Inattentive", question: "When you have a task that requires a lot of thought, how often do you avoid or delay getting started?" , value: [0,0,0,1,1] },
    {type: "A", subset: "Motor", question: "How often do you fidget or squirm with your hands or feet when you have to sit down for a long time?" , value: [0,0,0,1,1] },
    {type: "A", subset: "Motor", question: "How often do you feel overly active and compelled to do things, like you were driven by a motor?" , value: [0,0,0,1,1] },
    {type: "B", subset: "Inattentive", question: "How often do you make careless mistakes when you have to work on a boring or difficult project?" , value: [0,0,0,1,1] },
    {type: "B", subset: "Inattentive", question: "How often do you have difficulty keeping your attention when you are doing boring or repetitive work?" , value: [0,0,0,1,1] },
    {type: "B", subset: "Inattentive", question: "How often do you have difficulty concentrating on what people say to you, even when they are speaking to you directly?" , value: [0,0,1,1,1] },
    {type: "B", subset: "Inattentive", question: "How often do you misplace or have difficulty finding things at home or at work?" , value: [0,0,0,1,1] },
    {type: "B", subset: "Inattentive", question: "How often are you distracted by activity or noise around you?" , value: [0,0,0,1,1] },
    {type: "B", subset: "Motor", question: "How often do you leave your seat in meetings or other situations in which you are expected to remain seated?" , value: [0,0,1,1,1] },
    {type: "B", subset: "Motor", question: "How often do you feel restless or fidgety?" , value: [0,0,0,1,1] },
    {type: "B", subset: "Motor", question: "How often do you have difficulty unwinding and relaxing when you have time to yourself?" , value: [0,0,0,1,1] },
    {type: "B", subset: "Verbal", question: "How often do you find yourself talking too much when you are in social situations?" , value: [0,0,0,1,1] },
    {type: "B", subset: "Verbal", question: "When you’re in a conversation, how often do you find yourself finishing the sentences of the people you are talking to, before they can finish them themselves?" , value: [0,0,1,1,1] },
    {type: "B", subset: "Verbal", question: "How often do you have difficulty waiting your turn in situations when turn taking is required?" , value: [0,0,0,1,1] },
    {type: "B", subset: "Verbal", question: "How often do you interrupt others when they are busy?" , value: [0,0,1,1,1] },
]

const explanation = `The Adult ADHD Self-Report Scale (ASRS) is a screening tool used to assess symptoms of Attention Deficit Hyperactivity Disorder (ADHD) in adults. The ASRS consists of 18 questions, and respondents are asked to rate their experience of ADHD symptoms in the past 6 months on a 5-point scale ranging from "never" to "very often."

The total score on the ASRS can range from 0 to 72, with higher scores indicating a greater likelihood of ADHD symptoms. A score of 14 or higher on the ASRS Screener v1.1 is typically considered to be indicative of significant ADHD symptoms and may warrant further evaluation by a healthcare professional.

However, it is important to note that the ASRS is not a diagnostic tool for ADHD and should not be used as the sole basis for diagnosis. A comprehensive clinical assessment that includes evaluation of symptoms, impairment, and history is necessary to make a diagnosis of ADHD. The ASRS can be a useful tool in identifying individuals who may benefit from further evaluation or support.`

const citation = `Kessler, R.C., Adler, L., Ames, M., Demler, O., Faraone, S., Hiripi, E., Howes, M.J., Jin, R., Secnik, K., Spencer, T., Ustun, T.B., Walters, E.E. (2005). The World Health Organization Adult ADHD Self-Report Scale (ASRS). Psychological Medicine, 35(2), 245-256

Copyright © World Health Organization 2003 `
const title = "Adult ADHD Self-Report Scale v1.1";
const instrument = "ASRS";

class ASRS extends React.Component {

    constructor(props) {
            super(props);
            this.state = {
                identity: localStorage.getItem("auth"),
                score: "n/a",
                latestDate:"",
                A : 0,
                B : 0,
                inattentive : 0,
                verbal : 0,
                motor: 0,
                tool : props.tool
            };

            this.calculate = this.calculate.bind(this);
            this.save = this.save.bind(this);
            this.cancel = this.cancel.bind(this);

    };
    
    printing = () => {
        var htmlcode = document.getElementById('tool').innerHTML;
        var iframe = document.getElementById("CaredevoPrint");
        var printDocument = iframe.contentDocument;
        var printPreview = iframe.contentWindow;
        printPreview.focus();
    
        printDocument.open();
        printDocument.write(`<!DOCTYPE html>
        <html>
        <head>
        <link rel="stylesheet" type="text/css" media='print' href="/dist/print.css">
        </head>
        <style>

        body {
            line-height : 1.6;
            font-size : 12pt;
            padding-bottom  :50pt;
        }

        iframe {
            display : none;
        }
        @media print {        
            @page {
                size: A4;
                margin-top : 0.80cm;
                margin-bottom : 0.80cm;
            }
            
        * {
            -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
            color-adjust: exact !important;  /*Firefox*/
        }
        .section {
            page-break-inside: avoid;
            
        }

        .options {
            display : flex;
            flex-direction: row;
            margin-left : 20pt;
            margin-bottom : 10pt;
            border-bottom-style : solid;
            border-width: 0.5px;
            width: 490pt;
        }

        .optionblock {
            display : flex;
            flex-direction : row;
        }

        .qline {
            display : grid;
            grid-template-columns : 20pt 490pt;
           
        }
 
        .title {
            padding : 30pt
            width : 100%;
            height : 20pt;
            background-color: #42b3f5;
            background-image: linear-gradient(to right, #42b3f5 , white);
            border-radius: 5pt;

        }
        .title, .score {
            font-size:large;
            font-family: Arial, Helvetica, sans-serif;
            font-weight:600;
            color: black;
            padding: 10pt;
            text-align:center
        }
        .header {
            font-size : 13pt;
            color : black;
            font-weight : 600;
            margin-left : 10pt;
            margin-bottom : 20pt;
            margin-top : 20pt;
        }
        .explanation {
            margin-top : 10pt;
            margin-left : 20pt;
            white-space : pre-line;
            width: 450pt;
            text-align : justify;
            font-size : 10pt;
          
        }
        .button {
            display: none;
        }
        .decoration {
            position: fixed;
            font-family: Arial, Helvetica, sans-serif;
            font-weight:600;
            top:  300px;
            left : 100px;
            width : 300px;
            color : #42b3f5;
            opacity : 0.1;
            font-size : 140pt;
            letter-spacing : 2pt;
            z-index : -1;
        }
        </style>
        <body>`)
        printDocument.write(htmlcode);
        printDocument.write("<div class='decoration'>ASRS</div>");
        printDocument.write("</body></html>");
        printPreview.print();
        printPreview.close();
  
    }
    
    renderContent() {
        return (
            <div>
                <div className='header p-2 bg-gray-200 sticky top-0'>{header}</div>
    
                {questions.map((item, index) => (
                    <div className='section flex flex-col border-b-2' key={index}>
                    <div className='qline p-2 w-10/12 xs:w-auto flex flex-row gap-2'><div>{questions.indexOf(item)+1}.</div><div>{item.question}</div></div>
                    <div className='options flex flex-row text-sm w-8/12 xs:w-auto ml-6'>
                        <div className='optionblock flex flex-row xs:flex-col'>
                        <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",0]" } value={item.value[0]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> Never</div>
                        <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",1]" } value={item.value[1]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> Rarely</div>
                        <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",2]" } value={item.value[2]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> Sometimes</div>
                        </div>
                        <div className='optionblock flex flex-row xs:flex-col'>
                        <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",3]" } value={item.value[3]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> Often</div>
                        <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",4]" } value={item.value[4]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> Very Often</div>
                        </div>
                    </div>
                    </div>
                ))}
            </div>
        )

    }

    singleOption(e) {
        var current = JSON.parse(e.target.id);
        var qNumber = JSON.stringify(current[0]);
        var optionChecked = JSON.stringify(current[1]); 
        
        var optionNumber = questions[0].value.length;
        for (let i=0; i < optionNumber; i++) {
            var option = "[" + qNumber + "," + i + "]";
            document.getElementById(option).checked = false;
            document.getElementById(option).removeAttribute("checked");
        }

        var checkingOption = "[" + qNumber + "," + optionChecked + "]"
        document.getElementById(checkingOption).checked = true;
        document.getElementById(checkingOption).setAttribute("checked", "true");


    }

    calculate() {
      
        var finalscore = 0;
        var A = 0;
        var B = 0;
        var inattentive = 0;
        var motor = 0;
        var verbal = 0;


        var optionNumber = questions[0].value.length;
        var element, checking;
        var noBlank = 0;
        questions.map(item => {
            var questionNumber = questions.indexOf(item);
           
            for (let i=0; i < optionNumber; i++) {
                element = "[" + questionNumber + "," + i +"]";
                
                checking = document.getElementById(element).checked;

                if (checking === true) {
                    var value = parseInt(document.getElementById(element).value)
                    finalscore = finalscore + value;
               
                    if (item.type === 'A') {
                        A = A + value;
                    } else if (item.type === 'B') {
                        B = B + value;
                    }

                    if (item.subset === 'Inattentive') {
                        inattentive = inattentive + value;
                    } else if (item.subset === "Motor") {
                        motor = motor + value;
                    } else if (item.subset === "Verbal") {
                        verbal = verbal + value;
                    }
                 
                    noBlank = noBlank + 1;
                }
            }
            
        })

        if (noBlank !== questions.length) {
            toast.error(`You must answer all questions !`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar : false
            });
        }
        else if (noBlank === questions.length) {
            toast.success(`Total score successfully calculated`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar : false
            });
            var finalStatement = `Part A : ${A}/6, Part B : ${B}/12. Inattentive subtype: ${inattentive},  Hyperactive/Impulsive Motor : ${motor}  Hyperactive/Impulsive Verbal : ${verbal}`
            this.setState({score: finalscore + ". Part A : " +A + " Part B : " + B, A: A, B: B, inattentive : inattentive, motor : motor, verbal: verbal})
            this.props.addData({ tool: 'Adult ADHD Self Report Scale', result: finalStatement, interpretation: 'Score of 14 or higher typically considered to be indicative of significant ADHD symptoms and may warrant further evaluation' });
        }
      
    }

    cancel() {
        this.props.setState({tool: '', score: 'n/a'})
    }

    async save(e) {

        //save tool to mh_tool
        e.preventDefault();
        var newData = {};

   
        // axios post request
        newData['patientId'] = this.state.patientId;
        newData['userId'] = this.state.userId;
        newData['instrument'] = instrument;
        newData['score'] = this.state.score;
    
        var partANotes;      
        var inattentive = this.state.inattentive;
        var motor = this.state.motor;
        var verbal = this.state.verbal;
        if (this.state.A > 3) {
            partANotes = "As part A score is more than 4, this is considered to be highly consistent with ADHD diagnosis in adult."
            newData['notes'] = `Part A is ${this.state.A} and Part B is ${this.state.B}. Inattentive subscale : ${inattentive}, Hyperactivity/impulsivity motor : ${motor}, Hyperactivity/impulsivity verbal : ${verbal}. ${partANotes}`;
    
        } else {
            partANotes = "Less likely to be ADHD"
            newData['notes'] = partANotes;
        } 

        //check if it's been calculated first
        if (newData.score === 'n/a') {
            toast.error("Need to calculate the score before saving")
        } else {
            //close this panel by updating tool state in parent component
            this.props.setState({tool: '', ASRS : [newData.score, newData.notes], score: 'n/a'})
        }

    }

    render() {
        var latestDate = this.state.latestDate;
     
        return (
            <div className="fixed top-0 left-0 h-full w-full z-10 bg-gray-900/80">
                <div  id='tool' className="bg-white rounded p-6 xs:p-4 mx-auto  mt-20 xs:mt-0 h-4/5 w-8/12 xs:h-full xs:w-full">
                    <div className='title bg-sky-800 text-white text-md -mt-6 w-2/5 xs:w-4/5 -ml-6 px-3  py-2' >{title}</div>
                    <div className='content mt-4 h-3/4 xs:h-3/4 overflow-y-auto'>
                
                        {this.renderContent()}
                    </div>
                    <div className='mt-4 text-center'>
                    <div>
                        <button onClick={this.calculate} className='bg-yellow-600 focus:bg-yellow-700 font-semibold text-white p-2 w-32 xs:w-20 shadow-lg mx-2 rounded-lg'>Calculate</button>
                        { this.state.score === 'n/a' || this.state.tool === true ? (
                            <></>
                        ): (
                            <>
                            <button onClick={this.save} className='bg-sky-700 focus:bg-sky-800 font-semibold text-white p-2 w-32 xs:w-20 shadow-lg mx-2 rounded-lg'>Save</button>
                            </>
                        )}
                        { this.state.tool === true ? (
                            <>
                            <button onClick={this.printing} className=' bg-sky-700 focus:bg-sky-800 font-semibold text-white p-2 w-32 xs:w-20 shadow-lg mx-2 rounded-lg'>Print</button>
                            </>
                        ): (<></>)}
                        <button onClick={this.cancel} className='bg-red-700 focus:bg-red-800 font-semibold text-white p-2 w-32 xs:w-20 shadow-lg mx-2 rounded-lg'>Close</button>
                    </div>
                    <div className='score mt-2 xs:mt-1 font-semibold text-lg xs:text-sm'>
                
                        Part A : {this.state.A} of 6 &nbsp; Part B : {this.state.B} of 12
                        <p className='text-xs'>Inattentive subtype : {this.state.inattentive} &nbsp; Hyperactive/Impulsive Motor : {this.state.motor}&nbsp; Hyperactive/Impulsive Verbal : {this.state.verbal}</p> 
                        { latestDate ? (
                            <div className='text-sm'>last assessment saved on  <Moment format='DD MMMM YYYY'>{latestDate}</Moment></div>
                        ): (
                            <></>
                        )}
                    
                    </div>
                    </div>
                    <div className='explanation invisible'><b>Notes</b> <p>{explanation}</p></div>
                    <div className='explanation invisible'><b>Developer</b> <p>{citation}</p></div>
                    <iframe id="CaredevoPrint" className='hidden' ></iframe>
                </div>  
                <ToastContainer />
            </div>
        )

    }


};

export default ASRS;

