import React from 'react';
import Moment from 'react-moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const header = "Select option from symptoms below";
const questions = [
    {question: "Complains of aches/pains" , value: [0,1,2] },
    {question: "Spends more time alone" , value: [0,1,2] },
    {question: "Tires easily, has little energy" , value: [0,1,2] },
    {question: "Fidgety, unable to sit still" , value: [0,1,2] },
    {question: "Has trouble with a teacher" , value: [0,1,2] },
    {question: "Less interested in school" , value: [0,1,2] },
    {question: "Acts as if driven by a motor" , value: [0,1,2] },
    {question: "Daydreams too much" , value: [0,1,2] },
    {question: "Distracted easily" , value: [0,1,2] },
    {question: "Is afraid of new situations" , value: [0,1,2] },
    {question: "Feels sad, unhappy" , value: [0,1,2] },
    {question: "Is irritable, angry" , value: [0,1,2] },
    {question: "Feels hopeless" , value: [0,1,2] },
    {question: "Has trouble concentrating" , value: [0,1,2] },
    {question: "Less interest in friends" , value: [0,1,2] },
    {question: "Fights with others" , value: [0,1,2] },
    {question: "Absent from school" , value: [0,1,2] },
    {question: "School grades dropping" , value: [0,1,2] },
    {question: "Is down on him or herself" , value: [0,1,2] },
    {question: "Visits doctor with doctor finding nothing wrong" , value: [0,1,2] },
    {question: "Has trouble sleeping" , value: [0,1,2] },
    {question: "Worries a lot" , value: [0,1,2] },
    {question: "Wants to be with you more than before" , value: [0,1,2] },
    {question: "Feels he or she is bad" , value: [0,1,2] },
    {question: "Takes unnecessary risks" , value: [0,1,2] },
    {question: "Gets hurt frequently" , value: [0,1,2] },
    {question: "Seems to be having less fun " , value: [0,1,2] },
    {question: "Acts younger than children his or her age" , value: [0,1,2] },
    {question: "Does not listen to rules" , value: [0,1,2] },
    {question: "Does not show feelings" , value: [0,1,2] },
    {question: "Does not understand other people’s feelings " , value: [0,1,2] },
    {question: "Teases others" , value: [0,1,2] },
    {question: "Blames others for his or her troubles" , value: [0,1,2] },
    {question: "Takes things that do not belong to him or her" , value: [0,1,2] },
    {question: "Refuses to share" , value: [0,1,2] },

]

const title = "Paediatric Symptom Checklist";
const instrument = "PSC";
const explanation = `PSC stands for Pediatric Symptom Checklist. It is a brief questionnaire used to screen children for behavioral, emotional, and social problems. The PSC is a tool used by healthcare providers, teachers, and other professionals to identify children who may need further evaluation for mental health issues.

The PSC is completed by the child's caregiver and consists of 35 questions that assess the child's behavior and emotional well-being over the past month. The questions are designed to identify symptoms of depression, anxiety, attention-deficit/hyperactivity disorder (ADHD), conduct disorder, and other behavioral or emotional problems.

The PSC score is calculated based on the responses to the questionnaire and ranges from 0 to 35. Higher scores indicate a higher likelihood of behavioral or emotional problems. If a child scores above a certain threshold on the PSC, further evaluation or referral to a mental health professional may be recommended.`

const citation = `Gardner, W., Murphy, M., Childs, G., Kelleher, K., & Sturner, R. (1999). The PSC-17: a brief Pediatric Symptom Checklist with psychosocial problem subscales. A report from PROS and ASPN. Ambulatory Child Health, 5(3), 225–236.`

class PSC extends React.Component {

    constructor(props) {
            super(props);
            this.state = {
                identity: localStorage.getItem("auth"),
                score: "n/a",
                latestDate : "",
                tool : props.tool
            };

            this.calculate = this.calculate.bind(this);
            this.save = this.save.bind(this);
            this.cancel = this.cancel.bind(this);

    };

    printing = () => {
        var htmlcode = document.getElementById('tool').innerHTML;
        var iframe = document.getElementById("CaredevoPrint");
        var printDocument = iframe.contentDocument;
        var printPreview = iframe.contentWindow;
        printPreview.focus();

        printDocument.open();
        printDocument.write(`<!DOCTYPE html>
        <html>
        <head>
        <link rel="stylesheet" type="text/css" media='print' href="/dist/print.css">
        </head>
        <style>


        body {
            line-height : 1.6;
            font-size : 12pt;
            padding-bottom  :50pt;
        }

        iframe {
            display : none;
        }

        input {
            outline : none;
            border : transparent;
        }
        @media print {        
            @page {
                size: A4;
                margin-top : 0.80cm;
                margin-bottom : 0.80cm;
            }

        * {
            -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
            color-adjust: exact !important;  /*Firefox*/
        }

        .section {
            page-break-inside: avoid;
            
        }

        .options {
            display : flex;
            flex-direction: row;
            margin-left : 20pt;
            margin-bottom : 10pt;
            border-bottom-style : solid;
            border-width: 0.5px;
            width: 490pt;
        }

        .optionblock {
            display : flex;
            flex-direction : row;
        }

        .qline {
            display : grid;
            grid-template-columns : 20pt 490pt;
           
        }

        .title {
            padding : 30pt
            width : 100%;
            height : 20pt;
            background-color: #42b3f5;
            background-image: linear-gradient(to right, #42b3f5 , white);
            border-radius: 5pt;
        }

        .title, .score {
            font-size: 16pt;
            font-family: Arial, Helvetica, sans-serif;
            font-weight:500;
            color: black;
            display : block;
            padding: 10pt;
            text-align:center;
        }

        .header {
            font-size : 13pt;
            color : black;
            font-weight : 600;
            margin-left : 10pt;
            margin-bottom : 20pt;
            margin-top : 20pt;
        }
        .explanation {
            margin-top : 10pt;
            margin-left : 20pt;
            white-space : pre-line;
            width: 450pt;
            text-align : justify;
            font-size : 10pt; 
        }
        .button {
            display: none;
        }

        .decoration {
            position: fixed;
            font-family: Arial, Helvetica, sans-serif;
            font-weight:600;
            top:  300px;
            left : 100px;
            width : 300px;
            color : #42b3f5;
            opacity : 0.1;
            font-size : 140pt;
            letter-spacing : 2pt;
            z-index : -1;
        }
        
        </style>
        <body>`)
        printDocument.write(htmlcode);
        printDocument.write("<div class='decoration'>PSC</div>");
        printDocument.write("</body></html>");
        printPreview.print();
        printPreview.close();
  
    }
    
    renderContent() {
        return (
            <div>
                <div className='header p-4 bg-gray-200 sticky top-0'>{header}</div>
             
                {questions.map((item, index) => (
                    <div className='section flex flex-col border-b-2' key={index}>
                          <div className='qline p-2 w-10/12 xs:w-auto flex flex-row gap-2'><div>{questions.indexOf(item)+1}.</div><div>{item.question}</div></div>
                          <div className='options flex flex-row xs:flex-col w-11/12 xs:w-auto ml-6'>
                            <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",0]" } value={item.value[0]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> Never</div>
                            <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",1]" } value={item.value[1]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> Sometimes</div>
                            <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",2]" } value={item.value[2]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> Often</div>
                        </div> 
                    </div>
                    
                ))}
            </div>
        )

    }

    singleOption(e) {
        var current = JSON.parse(e.target.id);
        var qNumber = JSON.stringify(current[0]);
        var optionChecked = JSON.stringify(current[1]); 
        
        var optionNumber = questions[0].value.length;
        for (let i=0; i < optionNumber; i++) {
            var option = "[" + qNumber + "," + i + "]";
            document.getElementById(option).checked = false;
            document.getElementById(option).removeAttribute("checked");
        }

        var checkingOption = "[" + qNumber + "," + optionChecked + "]"
        document.getElementById(checkingOption).checked = true;
        document.getElementById(checkingOption).setAttribute("checked", "true");


    }

    calculate() {
      
        var finalscore = 0;
        var optionNumber = questions[0].value.length;
        var element, checking;
        var noBlank = 0;
        questions.map(item => {
            var questionNumber = questions.indexOf(item);
           
            for (let i=0; i < optionNumber; i++) {
                element = "[" + questionNumber + "," + i +"]";
                
                checking = document.getElementById(element).checked;

                if (checking === true) {
                    var value = parseInt(document.getElementById(element).value)
                    finalscore = finalscore + value;

                    noBlank = noBlank + 1;
                }
            }
            
        })

        if (noBlank !== questions.length) {
            toast.error(`You must answer all questions !`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar : false
            });
        }
        else if (noBlank === questions.length) {
            toast.success(`Total score successfully calculated`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar : false
            });
            this.setState({score: finalscore})
        }
      
    }

    cancel() {
        this.props.setState({tool: '', score:'n/a'})
    }

    async save(e) {

        //save tool to mh_tool
        e.preventDefault();
        var newData = {};
        var latestDate = this.state.latestDate;
        var date = new Date().toISOString();

        // axios post request
        newData['patientId'] = this.state.patientId;
        newData['userId'] = this.state.userId;
        newData['instrument'] = instrument;
        newData['score'] = this.state.score;

        if (newData.score > 27) {
            newData['notes'] = "Result indicates psychological impairment."
        } else {
            newData['notes'] = "Result does not indicate psychological impairment."
        }


        //check if it's been calculated first
        if (newData.score === 'n/a') {
            toast.error("Need to calculate the score before saving")
        } else {

            //close this panel by updating tool state in parent component
            this.props.setState({tool: '', PSC : [newData.score, newData.notes], score:'n/a'})
        }
    }

    render() {
        var latestDate = this.state.latestDate;
     
        return (
            <div className="fixed top-0 left-0 h-full w-full z-10 bg-gray-900/80">
                <div id='tool' className=" bg-white rounded p-6 xs:p-4 mx-auto  mt-20 xs:mt-0 h-4/5 w-8/12 xs:h-full xs:w-full">
                    <div className='title bg-sky-800 text-white text-lg -mt-6 w-2/5 xs:w-4/5 -ml-6 px-3  py-2' >{title}</div>
                    <div className='content mt-4 h-4/5 xs:h-4/5 overflow-y-auto'>
                
                        {this.renderContent()}
                    </div>
                    <div className='mt-4 text-center'>
                    <div>
                        <button onClick={this.calculate} className='bg-yellow-600 focus:bg-yellow-700 font-semibold text-white p-2 w-32 xs:w-20 shadow-lg mx-2 rounded-lg'>Calculate</button>
                        { this.state.score === 'n/a' || this.state.tool === true ? (
                            <></>
                        ): (
                            <>
                            <button onClick={this.save} className='bg-sky-700 focus:bg-sky-800 font-semibold text-white p-2 w-32 xs:w-20 shadow-lg mx-2 rounded-lg'>Save</button>
                            </>
                        )}
                        { this.state.tool === true ? (
                            <>
                            <button onClick={this.printing} className=' bg-sky-700 focus:bg-sky-800 font-semibold text-white p-2 w-32 xs:w-20 shadow-lg mx-2 rounded-lg'>Print</button>
                            </>
                        ): (<></>)}
                        <button onClick={this.cancel} className='bg-red-700 focus:bg-red-800 font-semibold text-white p-2 w-32 xs:w-20 shadow-lg mx-2 rounded-lg'>Close</button>
                    </div>
                    <div className='score mt-2 xs:mt-1 font-semibold text-2xl xs:text-lg'>
                        Score : {this.state.score}
                        { latestDate ? (
                            <div className='text-sm'>last assessment saved on  <Moment format='DD MMMM YYYY'>{latestDate}</Moment></div>
                        ): (
                            <></>
                        )}
                    </div>
                    <div className='explanation invisible'><b>Notes</b> <p>{explanation}</p></div>
                    <div className='explanation invisible'><b>Developer</b> <p>{citation}</p></div>
                    <iframe id="CaredevoPrint" className='hidden' height='0' width='0'></iframe>
                    </div>

                </div>  
                <ToastContainer />
            </div>
        )

    }

};

export default PSC;

