import React from 'react';
import Moment from 'react-moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const header = "Mark for each question how often the following applied to your child.";
  
const questions = [
    {type: "inattentive", number : "1", question: "Does not pay attention to details or makes careless mistakes with, for example, homework", value: [0,1,2,3] },
    {type: "inattentive", number : "2", question: "Has difficulty keeping attention to what needs to be done", value: [0,1,2,3] },
    {type: "inattentive", number : "3", question: "Does not seem to listen when spoken to directly", value: [0,1,2,3] },
    {type: "inattentive", number : "4", question: "Does not follow through when given directions and fails to finish activities (not due to refusal or failure to understand)", value: [0,1,2,3] },
    {type: "inattentive", number : "5", question: "Has difficulty organizing tasks and activities", value: [0,1,2,3] },

    {type: "inattentive", number : "6", question: "Avoids, dislikes, or does not want to start tasks that require ongoing mental effort", value: [0,1,2,3] },
    {type: "inattentive", number : "7", question: "Loses things necessary for tasks or activities (toys, assignments, pencils, or books)", value: [0,1,2,3] },
    {type: "inattentive", number : "8", question: "Is easily distracted by noises or other stimuli", value: [0,1,2,3] },
    {type: "inattentive", number : "9", question: "Is forgetful in daily activities", value: [0,1,2,3] },
    {type: "hyperactive", number : "10", question: "Fidgets with hands or feet or squirms in seat", value: [0,1,2,3] },

    {type: "hyperactive", number : "11", question: "Leaves seat when remaining seated is expected", value: [0,1,2,3] },
    {type: "hyperactive", number : "12", question: "Runs about or climbs too much when remaining seated is expected", value: [0,1,2,3] },
    {type: "hyperactive", number : "13", question: "Has difficulty playing or beginning quiet play activities", value: [0,1,2,3] },
    {type: "hyperactive", number : "14", question: "Is “on the go” or often acts as if “driven by a motor”", value: [0,1,2,3] },
    {type: "hyperactive", number : "15", question: "Talks too much", value: [0,1,2,3] },

    {type: "hyperactive", number : "16", question: "Blurts out answers before questions have been completed", value: [0,1,2,3] },
    {type: "hyperactive", number : "17", question: "Has difficulty waiting his or her turn", value: [0,1,2,3] },
    {type: "hyperactive", number : "18", question: "Interrupts or intrudes in on other’s conversations and/or activities", value: [0,1,2,3] },
    {type: "odd", number : "19", question: "Argues with adults", value: [0,1,2,3] },
    {type: "odd", number : "20", question: "Loses temper", value: [0,1,2,3] },

    {type: "odd", number : "21", question: "Actively defies or refuses to go along with adult’s requests or rules", value: [0,1,2,3] },
    {type: "odd", number : "22", question: "Deliberately annoys people", value: [0,1,2,3] },
    {type: "odd", number : "23", question: "Blames others for his or her mistakes or misbehaviors", value: [0,1,2,3] },
    {type: "odd", number : "24", question: "Is touchy or easily annoyed by others", value: [0,1,2,3] },
    {type: "odd", number : "25", question: "Is angry or resentful", value: [0,1,2,3] },

    {type: "odd", number : "26", question: "Is spiteful and wants to get even", value: [0,1,2,3] },
    {type: "conduct", number : "27", question: "Bullies, threatens, or intimidates others", value: [0,1,2,3] },
    {type: "conduct", number : "28", question: "Starts physical fights", value: [0,1,2,3] },
    {type: "conduct", number : "29", question: "Lies to get out of trouble or to avoid obligations (i.e.,“cons” others)", value: [0,1,2,3] },
    {type: "conduct", number : "30", question: "Is truant from school (skips school) without permission", value: [0,1,2,3] },

    {type: "conduct", number : "31", question: "Is physically cruel to people", value: [0,1,2,3] },
    {type: "conduct", number : "32", question: "Has stolen things that have value", value: [0,1,2,3] },
    {type: "conduct", number : "33", question: "Deliberately destroys others’ property", value: [0,1,2,3] },
    {type: "conduct", number : "34", question: "Has used a weapon that can cause serious harm (bat, knife, brick, gun)", value: [0,1,2,3] },
    {type: "conduct", number : "35", question: "Is physically cruel to animals", value: [0,1,2,3] },

    {type: "conduct", number : "36", question: "Has deliberately set fires to cause damage", value: [0,1,2,3] },
    {type: "conduct", number : "37", question: "Has broken into someone else’s home, business, or car", value: [0,1,2,3] },
    {type: "conduct", number : "38", question: "Has stayed out at night without permission", value: [0,1,2,3] },
    {type: "conduct", number : "39", question: "Has run away from home overnight", value: [0,1,2,3] },
    {type: "conduct", number : "40", question: "Has forced someone into sexual activity", value: [0,1,2,3] },

    {type: "anxiety", number : "41", question: "Is fearful, anxious, or worried", value: [0,1,2,3] },
    {type: "anxiety", number : "42", question: "Is afraid to try new things for fear of making mistakes", value: [0,1,2,3] },
    {type: "anxiety", number : "43", question: "Feels worthless or inferior", value: [0,1,2,3] },
    {type: "anxiety", number : "44", question: "Blames self for problems, feels guilty", value: [0,1,2,3] },
    {type: "anxiety", number : "45", question: "Feels lonely, unwanted, or unloved; complains that “no one loves him or her”", value: [0,1,2,3] },
    {type: "anxiety", number : "46", question: "Is sad, unhappy, or depressed", value: [0,1,2,3] },
    {type: "anxiety", number : "47", question: "Is self-conscious or easily embarrassed", value: [0,1,2,3] },

    {type: "performance", number : "48", question: "Overall school performance", value: [0,1,2,3] },
    {type: "performance", number : "49", question: "Reading", value: [0,1,2,3] },
    {type: "performance", number : "50", question: "Writing", value: [0,1,2,3] },

    {type: "performance", number : "51", question: "Mathematics", value: [1,2,3,4,5] },
    {type: "performance", number : "52", question: "Relationship with parents", value: [1,2,3,4,5] },
    {type: "performance", number : "53", question: "Relationship with siblings", value: [1,2,3,4,5]  },
    {type: "performance", number : "54", question: "Relationship with peers", value: [1,2,3,4,5] },
    {type: "performance", number : "55", question: "Participation in organized activities", value: [1,2,3,4,5]  },
]

const title = "Vanderbilt ADHD Diagnostic Rating Scale (VADRS)";
const instrument = "VADRS";
const explanation = `The Vanderbilt ADHD Rating Scale is a tool used by healthcare professionals, typically paediatricians or child psychiatrists, to help diagnose and monitor Attention-Deficit/Hyperactivity Disorder (ADHD) in children and adolescents. 

The scale is a standardized questionnaire that is completed by parents, teachers, and sometimes the child or adolescent themselves, to gather information about the child's behaviour and symptoms related to ADHD. 

The questionnaire covers areas such as inattention, hyperactivity, impulsivity, and social interactions, and the results are used to make a diagnosis of ADHD and to monitor the effectiveness of treatment. 

The rules for scoring are as follows:
ADHD inattentive type: Must score either a 2 or 3 on six or more items in questions 1–9, and score of 1 or 2 on any items in the performance section.
ADHD hyperactive/impulsive type: Must score either a 2 or 3 on six or more items in questions 10–18, and a score of 1 or 2 on any items in the performance section.
ADHD combined type: Meets criteria for both ADHD inattentive type and hyperactive/impulsive type.
Oppositional defiant disorder (ODD): Must score either a 2 or a 3 on four or more items in questions 19–26.
Conduct disorder: Must score either a 2 or 3 on three or more items in questions 27–40.
Anxiety/depression: Must score either a 2 or 3 on three or more items in questions 41–47.
`


const citation = `M Wolraich, W Lambert, M Doffing, L Bickman, T Simmons, K Worley. Psychometric Properties of the Vanderbilt ADHD Diagnostic Parent Rating Scale in a Referred Population. Journal of Pediatric Psychology. 28(8): 559-568 (2003).`


class VADRS extends React.Component {

    constructor(props) {
            super(props);
            this.state = {
                identity: localStorage.getItem("auth"),
                score: "n/a",
                latest:"", 
                inattentive : 0,
                hyperactive : 0,
                odd : 0,
                conduct : 0,
                anxiety : 0,
                notes :"",
                tool: props.tool
            };

            this.calculate = this.calculate.bind(this);
            this.save = this.save.bind(this);
            this.cancel = this.cancel.bind(this);

    };

    printing = () => {
        var htmlcode = document.getElementById('tool').innerHTML;
        var iframe = document.getElementById("CaredevoPrint");
        var printDocument = iframe.contentDocument;
        var printPreview = iframe.contentWindow;
        printPreview.focus();
     
        printDocument.open();
        printDocument.write(`<!DOCTYPE html>
        <html>
        <head>
        <link rel="stylesheet" type="text/css" media='print' href="/dist/print.css">
        </head>
        <style>

        body {
            line-height : 1.6;
            font-size : 12pt;
            padding-bottom  :50pt;
        }

        iframe {
            display : none;
        }

        input {
            outline : none;
            border : transparent;
        }
        @media print {        
            @page {
                size: A4;
                margin-top : 0.80cm;
                margin-bottom : 0.80cm;
            }

        * {
            -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
            color-adjust: exact !important;  /*Firefox*/
        }
        .section {
            page-break-inside: avoid;
            
        }

        .options {
            display : flex;
            flex-direction: row;
            margin-left : 20pt;
            margin-bottom : 10pt;
            border-bottom-style : solid;
            border-width: 0.5px;
            width: 490pt;
        }

        .optionblock {
            display : flex;
            flex-direction : row;
        }

        .qline {
            display : grid;
            grid-template-columns : 20pt 490pt;
           
        }

        .title {
            padding : 30pt
            width : 100%;
            height : 20pt;
            background-color: #42b3f5;
            background-image: linear-gradient(to right, #42b3f5 , white);
            border-radius: 5pt;

        }

        .title, .score {
            font-size:large;
            font-family: Arial, Helvetica, sans-serif;
            font-weight:600;
            color: black;
            padding: 10pt;
            text-align:center
        }
        .header {
            font-size : 13pt;
            color : black;
            font-weight : 600;
            margin-left : 10pt;
            margin-bottom : 20pt;
            margin-top : 20pt;
        }
        .explanation {
            margin-top : 10pt;
            margin-left : 20pt;
            white-space : pre-line;
            width: 450pt;
            text-align : justify;
            font-size : 10pt;
          
        }
        .button {
            display: none;
        }
        .decoration {
            position: fixed;
            font-family: Arial, Helvetica, sans-serif;
            font-weight:600;
            top:  300px;
            left : 70px;
            width : 300px;
            color : #42b3f5;
            opacity : 0.1;
            font-size : 140pt;
            letter-spacing : 2pt;
            z-index : -1;
        }
        </style>
        <body>`)
        printDocument.write(htmlcode);
        printDocument.write("<div class='decoration'>VADRS</div>");
        printDocument.write("</body></html>");
        printPreview.print();
        printPreview.close();
  
    }
    
    renderContent() {
        return (

        <div>
            <div className='header p-4 bg-gray-200 sticky top-0'>{header}</div>

            {questions.slice(0,47).map((item, index) => (
                <div className='section flex flex-col border-b-2' key={index}>
                      <div className='qline p-2 w-10/12 xs:w-auto flex flex-row gap-2'><div>{questions.indexOf(item)+1}.</div><div>{item.question}</div></div>
                      <div className='flex flex-row xs:flex-col options w-11/12 xs:w-auto ml-6'>
                        <div className='optionblock flex flex-row text-xs'>
                            <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",0]" } value={item.value[0]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> Never</div>
                            <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",1]" } value={item.value[1]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> Occasionally</div>
                        </div>
                        <div  className='optionblock flex flex-row text-xs'>                        
                            <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",2]" } value={item.value[2]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> Often</div>
                            <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",3]" } value={item.value[3]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> Very often</div>
                        </div>
                    </div> 
                </div>
                
            ))}

            {questions.slice(47,55).map((item) => (
                    <div className='section flex flex-col border-b-2'>
                    <div className='qline p-2 w-10/12 xs:w-auto flex flex-row gap-2'><div>{questions.indexOf(item)+1}.</div><div>{item.question}</div></div>
                    <div className='flex flex-row xs:flex-col options w-11/12 xs:w-auto ml-6'>
                        <div className='flex flex-row text-xs'>
                        <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",0]" } value={item.value[0]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> Excellent</div>
                        <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",1]" } value={item.value[1]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> Above average</div>
                        <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",2]" } value={item.value[2]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> Average</div>
                        </div>
                        <div className='flex flex-row text-xs'>
                        <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",3]" } value={item.value[3]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> Somewhat a problem</div>
                        <div className='p-2'><input onChange={this.singleOption} id={"[" + questions.indexOf(item) + ",4]" } value={item.value[4]} className='w-6 h-6 border-gray-300 rounded' type='checkbox' /> Problematic</div>
                        </div>
                    </div> 
                </div>
                
            ))}

        </div>
        )

    }

    singleOption(e) {
        var current = JSON.parse(e.target.id);
        var qNumber = JSON.stringify(current[0]);
        var optionChecked = JSON.stringify(current[1]); 
        
        var optionNumber = questions[0].value.length;
        var optionNumber2 = questions[48].value.length;
        var optionNumber3 = questions[50].value.length;

        if (qNumber < 48 ) {
            for (let i=0; i < optionNumber; i++) {
                var option = "[" + qNumber + "," + i + "]";
                document.getElementById(option).checked = false;
                document.getElementById(option).removeAttribute("checked");
            }
        } else if (qNumber > 47 && qNumber < 50) {
            for (let i=0; i < optionNumber2; i++) {
                var option = "[" + qNumber + "," + i + "]";
                document.getElementById(option).checked = false;
                document.getElementById(option).removeAttribute("checked");
            }
        } else if (qNumber > 49) {
            for (let i=0; i < optionNumber3; i++) {
                var option = "[" + qNumber + "," + i + "]";
                document.getElementById(option).checked = false;
                document.getElementById(option).removeAttribute("checked");
            }
        }
    

        var checkingOption = "[" + qNumber + "," + optionChecked + "]"
        document.getElementById(checkingOption).checked = true;
        document.getElementById(checkingOption).setAttribute("checked", "true");

        
    }

    calculate() {
      
        var inattentive = 0;
        var hyperactive = 0;
        var odd = 0;
        var conduct = 0;
        var anxiety = 0;
        var performance4 = 0;
        var performance5 = 0;
        // var finalscore = 0;

        var optionNumber = questions[0].value.length;
        var optionNumber2 = questions[47].value.length;
        var optionNumber3 = questions[49].value.length;
        var element, checking;
        var noBlank = 0;

        //checking question 1-48
        questions.slice(0,47).map(item => {
            var questionNumber = questions.indexOf(item);
           
            for (let i=0; i < optionNumber; i++) {
                element = "[" + questionNumber + "," + i +"]";
                
                checking = document.getElementById(element).checked;

                if (checking === true) {
                    var value = parseInt(document.getElementById(element).value)
                    // finalscore = finalscore + value;
               
                    if (item.type.includes('inattentive')) {
                        if (value === 2 || value === 3) {
                            inattentive = inattentive + 1;
                        }
                    } else if (item.type.includes("hyperactive")) {
                        if (value === 2 || value ===3) {
                            hyperactive = hyperactive + 1;
                        }        
                    } else if (item.type.includes("odd")) {
                        if (value === 2 || value ===3) {
                            odd = odd + 1;
                        }      
                    } else if (item.type.includes("conduct")) {
                        if (value === 2 || value ===3) {
                            conduct = conduct + 1;
                        }
                        
                    } else if (item.type.includes("anxiety")) {
                        if (value === 2 || value ===3) {
                            anxiety = anxiety + 1;
                        }
                        
                    }
                    noBlank = noBlank + 1;
                }
            }     
        })

        //checking question 48-50
        questions.slice(47,50).map(item => {
            var questionNumber = questions.indexOf(item);
           
            for (let i=0; i < optionNumber2; i++) {
                element = "[" + questionNumber + "," + i +"]";
                checking = document.getElementById(element).checked;

                if (checking === true) {
                    var value = parseInt(document.getElementById(element).value)
                    // finalscore = finalscore + value;
               
                    if (value === 4 ) {
                        performance4 = performance4 + 1;
                    } else if (value === 5) {
                        performance5 = performance5 + 1;
                    }
                  
                    noBlank = noBlank + 1;
                }
            }     
        })

        //checking question 50-55
        questions.slice(50,55).map(item => {
            var questionNumber = questions.indexOf(item);
            
            for (let i=0; i < optionNumber3; i++) {
                element = "[" + questionNumber + "," + i +"]";

                checking = document.getElementById(element).checked;

                if (checking === true) {
                    var value = parseInt(document.getElementById(element).value)
                    // finalscore = finalscore + value;
                
                    if (value === 4 ) {
                        performance4 = performance4 + 1;
                    } else if (value === 5) {
                        performance5 = performance5 + 1;
                    }
                    
                    noBlank = noBlank + 1;
                }
            }   
        })


        //combining results with performance items
        var performanceAffected, inattentiveCalc, hyperactiveCalc, combinedCalc, oddCalc, conductCalc, anxietyCalc;
        if (performance4 > 1 || performance5 > 0) {
            performanceAffected = true;
        } else {
            performanceAffected = false;
        }
        var totalPerformance = performance4 + performance5;

        if (inattentive > 5 && performanceAffected === true) {
            inattentiveCalc = "ADHD Inattentive subtype identified."
        } else {
            inattentiveCalc = "No ADHD Inattentive subtype."
        }

        if (hyperactive > 5 && performanceAffected === true) {
            hyperactiveCalc = "ADHD Hyperactive identified."
        } else {
            hyperactiveCalc = "No ADHD Hyperactive subtype."
        }

        if (hyperactive > 5 && inattentive > 5 && performanceAffected === true) {
            combinedCalc = "ADHD combined subtypes identified."
        } else {
            combinedCalc = "No ADHD combined subtypes."
        }

        if (odd > 4) {
            oddCalc = "Oppositional Defiant Disorder identified."
        } else {
            oddCalc = "No Oppositional Defiant Disorder."
        }

        if (conduct > 2) {
            conductCalc = "Conduct Disorder identified."
        } else {
            conductCalc = "No Conduct Disorder"
        }

        if (anxiety > 2) {
            anxietyCalc = "Anxiety/Depression present."
        } else {
            anxietyCalc = "Anxiety/Depression not present."
        }

        if (noBlank !== questions.length) {
             toast.error(`You must answer all questions`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar : false
            });
        }
        else if (noBlank === questions.length) {
            toast.success(`Total score successfully calculated`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar : false
            });

            var score = `${inattentiveCalc} ${hyperactiveCalc} ${combinedCalc} ${oddCalc} ${conductCalc} ${anxietyCalc}`
            this.setState({score: score})
            this.setState({inattentive: inattentive, hyperactive: hyperactive, odd: odd, conduct: conduct, anxiety: anxiety});
            var result = `Inattentive: ${inattentive}, Hyperactive: ${hyperactive}, ODD: ${odd}, Conduct: ${conduct}, anxiety: ${anxiety} performance: ${totalPerformance}`
            this.props.addData({ tool: 'Vanderbilt ADHD Diagnostic Rating Scale (VADRS)', result: result, interpretation: score });
        }
      
    }

    cancel() {
        this.props.setState({tool: '', score: 'n/a'})
    }

    async save(e) {

        //save tool to mh_tool
        e.preventDefault();
        var newData = {};
   
        // axios post request
        newData['patientId'] = this.state.patientId;
        newData['userId'] = this.state.userId;
        newData['instrument'] = instrument;
        newData['score'] = this.state.score;

        var notes = `Result indicates ${this.state.score}`


        newData['notes'] = notes
        

        //check if it's been calculated first
        if (newData.score === 'n/a') {
            toast.error("Need to calculate the score before saving")
        } else {

            //close this panel by updating tool state in parent component
            this.props.setState({tool: '', VADRS : [newData.score, newData.notes], score: 'n/a'})
        }

    }

    render() {
        var latestDate = this.state.latestDate;
     
        return (
            <div className="fixed top-0 left-0 h-full w-full z-10 bg-gray-900/80">
                <div id='tool' className="bg-white rounded p-6 xs:p-4 mx-auto  mt-20 xs:mt-0 h-4/5 w-8/12 xs:h-full xs:w-full">
                    <div className='title bg-sky-800 text-white text-md -mt-6 w-2/5 xs:w-4/5 -ml-6 px-3  py-2' >{title}</div>
                    <div className='content mt-4 h-3/4 xs:h-3/4 overflow-y-auto'>
                
                        {this.renderContent()}
                    </div>
                    <div className='mt-4 text-center'>
                    <div>
                        <button onClick={this.calculate} className='bg-yellow-600 focus:bg-yellow-700 font-semibold text-white p-2 w-32 xs:w-20 shadow-lg mx-2 rounded-lg'>Calculate</button>
                        { this.state.score === 'n/a' || this.state.tool === true ? (
                            <></>
                        ): (
                            <>
                            <button onClick={this.save} className='bg-sky-700 focus:bg-sky-800 font-semibold text-white p-2 w-32 xs:w-20 shadow-lg mx-2 rounded-lg'>Save</button>
                            </>
                        )}
                        { this.state.tool === true ? (
                            <>
                            <button onClick={this.printing} className=' bg-sky-700 focus:bg-sky-800 font-semibold text-white p-2 w-32 xs:w-20 shadow-lg mx-2 rounded-lg'>Print</button>
                            </>
                        ): (<></>)}
                        <button onClick={this.cancel} className='bg-red-700 focus:bg-red-800 font-semibold text-white p-2 w-32 xs:w-20 shadow-lg mx-2 rounded-lg'>Close</button>
                    </div>
                    <div className='score mt-2 xs:mt-1 font-semibold text-sm xs:text-sm'>
                        Result : 
                        <p>ADHD-Inattentive type : {this.state.inattentive}   ADHD-Hyperactive type : {this.state.hyperactive}</p>
                        <p>Oppositional Defiant Disorder : {this.state.odd}   Conduct Disorder : {this.state.conduct}</p>
                        <p>Anxiety/Depression : {this.state.anxiety}</p>
                        <p>{this.state.score}</p>
                        { latestDate ? (
                            <div className='text-sm'>last assessment saved on  <Moment format='DD MMMM YYYY'>{latestDate}</Moment></div>
                        ): (
                            <></>
                        )}
                    </div>
                    <div className='explanation invisible'><b>Notes</b> <p>{explanation}</p></div>
                    <div className='explanation invisible'><b>Developer</b> <p>{citation}</p></div>
                    <iframe id="CaredevoPrint" className='hidden'></iframe>
                    </div>

                </div>  
                <ToastContainer />
            </div>
        )

    }

};

export default VADRS;

